import React from 'react';
import {type IconType} from '..';

export const IconDashboard: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M0.535265 0.528816C0.403149 0.653773 0.32666 0.834266 0.32666 1.01476V8.65794C0.32666 9.03975 0.632614 9.35215 1.02201 9.35215H6.5848C6.96725 9.35215 7.28015 9.03975 7.28015 8.65794V1.0217C7.28015 0.632947 6.96725 0.327497 6.5848 0.327497L1.02201 0.313613V0.306671C0.834265 0.306671 0.660428 0.376092 0.528311 0.50799L0.535265 0.528816Z"
        fill="currentColor"
      />
      <path
        d="M15.6313 0.329604L10.0685 0.336546C9.67912 0.336546 9.37317 0.641996 9.37317 1.03075V4.50177C9.37317 4.88358 9.67912 5.19597 10.0685 5.19597H15.6313C16.0138 5.19597 16.3267 4.88358 16.3267 4.50177V1.01478C16.3267 0.626029 16.0138 0.32058 15.6313 0.32058V0.329604Z"
        fill="currentColor"
      />
      <path
        d="M7.0785 16.0998C7.20367 15.9679 7.28015 15.7874 7.28015 15.6069V12.1359C7.28015 11.7472 6.96725 11.4417 6.5848 11.4417H1.02201C0.632614 11.4417 0.32666 11.7472 0.32666 12.1359V15.593C0.32666 15.9749 0.632614 16.2872 1.02201 16.2872L6.5848 16.2942V16.2935C6.7656 16.2935 6.93943 16.2171 7.07155 16.0852L7.0785 16.0998Z"
        fill="currentColor"
      />
      <path
        d="M15.6313 7.28202H10.0685C9.67912 7.28202 9.37317 7.58747 9.37317 7.97622V15.6125C9.37317 15.9943 9.67912 16.3067 10.0685 16.3067L15.6313 16.2928C16.0138 16.2928 16.3267 15.9804 16.3267 15.5986V7.97067C16.3267 7.58192 16.0138 7.27647 15.6313 7.27647V7.28202Z"
        fill="currentColor"
      />
    </svg>
  );
};
