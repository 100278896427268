import React from 'react';
import {type IconType} from '../../icons';

export const LightBulb: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M56.0073 41.678C55.7425 41.3243 55.2411 41.2523 54.8874 41.5171C54.5338 41.7819 54.4617 42.2833 54.7266 42.637L63.5734 54.4518C63.8383 54.8055 64.3396 54.8775 64.6933 54.6127C65.047 54.3479 65.119 53.8465 64.8542 53.4928L56.0073 41.678Z"
        fill="#001F5C"
      />
      <path
        d="M121.387 55.9089C121.774 55.6952 121.914 55.2085 121.7 54.8218C121.487 54.4351 121 54.2948 120.613 54.5085L104.955 63.1618C104.568 63.3755 104.428 63.8623 104.642 64.249C104.855 64.6357 105.342 64.7759 105.729 64.5622L121.387 55.9089Z"
        fill="#001F5C"
      />
      <path
        d="M100.631 95.0508C100.929 94.7247 101.435 94.702 101.761 95.0002L116.183 108.186C116.509 108.484 116.532 108.99 116.234 109.316C115.935 109.642 115.429 109.665 115.103 109.367L100.681 96.181C100.355 95.8829 100.333 95.3768 100.631 95.0508Z"
        fill="#001F5C"
      />
      <path
        d="M62.205 96.564C62.515 96.2491 62.511 95.7426 62.1962 95.4326C61.8814 95.1226 61.3749 95.1266 61.0649 95.4414C55.8994 100.688 53.4144 103.546 50.9357 106.397L50.9337 106.4C48.4671 109.237 46.0058 112.068 40.8739 117.281C40.5639 117.595 40.5679 118.102 40.8827 118.412C41.1975 118.722 41.704 118.718 42.014 118.403C47.1795 113.157 49.6644 110.299 52.1432 107.447L52.1452 107.445C54.6118 104.607 57.073 101.776 62.205 96.564Z"
        fill="#001F5C"
      />
      <path
        d="M38.2 77.0476C38.2 76.6058 38.5581 76.2476 39 76.2476H51.3618C51.8036 76.2476 52.1618 76.6058 52.1618 77.0476C52.1618 77.4894 51.8036 77.8476 51.3618 77.8476H39C38.5581 77.8476 38.2 77.4894 38.2 77.0476Z"
        fill="#001F5C"
      />
      <path
        d="M89.1516 94.3544C89.1516 93.8584 88.7495 93.4563 88.2536 93.4563C87.7576 93.4563 87.3555 93.8584 87.3555 94.3544V103.214C87.3555 107.383 83.9756 110.763 79.8063 110.763C75.637 110.763 72.2571 107.383 72.2571 103.214V94.9998C72.2571 94.5038 71.8551 94.1017 71.3591 94.1017C70.8631 94.1017 70.461 94.5038 70.461 94.9998V103.214C70.461 108.375 74.645 112.559 79.8063 112.559C84.9676 112.559 89.1516 108.375 89.1516 103.214V94.3544Z"
        fill="#001F5C"
      />
      <path
        d="M73.5346 73.7635C73.8892 75.6643 74.7794 77.1407 75.9158 78.2159C76.8009 77.1123 77.4006 75.4649 77.5077 73.3435C77.5904 71.7058 77.2501 70.6287 76.7958 70.0021C76.3506 69.3882 75.791 69.1877 75.2912 69.2536C74.3107 69.3827 72.9723 70.7488 73.5346 73.7635Z"
        fill="#3164FA"
      />
      <path
        d="M85.4237 71.8414C86.6662 73.5816 86.5445 75.9875 85.4656 77.845C85.1359 77.5113 84.8402 77.1435 84.5884 76.7448C83.1768 74.5104 83.2006 72.6236 83.6507 71.7032C83.8751 71.2444 84.1556 71.0962 84.3654 71.0875C84.5832 71.0784 84.9716 71.2082 85.4237 71.8414Z"
        fill="#3164FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.213 97.5622C92.1607 97.5622 101.846 87.8767 101.846 75.9291C101.846 63.9814 92.1607 54.2959 80.213 54.2959C68.2654 54.2959 58.5799 63.9814 58.5799 75.9291C58.5799 87.8767 68.2654 97.5622 80.213 97.5622ZM78.6986 73.4036C78.79 71.5939 78.4252 70.218 77.7611 69.3021C77.0878 68.3736 76.1155 67.9422 75.1355 68.0713C73.1562 68.3321 71.7433 70.6631 72.3624 73.9822C72.7638 76.1339 73.7734 77.8176 75.0623 79.0494C74.7809 79.2603 74.483 79.4295 74.1727 79.5556C72.4059 80.2737 69.9191 79.7185 67.2419 76.9013C67.015 76.6627 66.6377 76.653 66.399 76.8799C66.1603 77.1067 66.1507 77.4841 66.3775 77.7228C69.2238 80.7178 72.2096 81.6407 74.6217 80.6603C75.1203 80.4576 75.5805 80.179 75.9983 79.8325C76.471 80.1779 76.9668 80.4722 77.4732 80.7161C79.7892 81.8317 82.4386 81.9376 84.1268 80.9019C84.7072 80.5458 85.2268 80.0984 85.6745 79.5857C87.0854 80.5999 88.7953 81.1724 90.4538 81.1962C93.1215 81.2346 95.6955 79.8455 96.6583 76.6069C96.7521 76.2913 96.5723 75.9593 96.2567 75.8655C95.941 75.7717 95.6091 75.9515 95.5153 76.2671C94.7243 78.928 92.6765 80.0356 90.471 80.0039C89.0545 79.9835 87.5894 79.4899 86.3828 78.6261C87.7746 76.3824 87.9936 73.3885 86.3942 71.1485C85.8193 70.3433 85.0957 69.8637 84.3159 69.896C83.5279 69.9287 82.925 70.4728 82.5795 71.1793C81.8899 72.5895 82.0284 74.9252 83.5802 77.3817C83.9153 77.912 84.3138 78.3945 84.7601 78.8244C84.3964 79.2355 83.9752 79.5959 83.5033 79.8854C82.277 80.6377 80.0839 80.6501 77.9907 79.6418C77.5977 79.4525 77.2123 79.2289 76.8421 78.9702C77.9256 77.6191 78.5834 75.6862 78.6986 73.4036Z"
        fill="#3164FA"
      />
    </svg>
  );
};
