import React from 'react';
import {type IconType} from '../../../icons';

export const DoubleCorrect: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M199.909 120.335C189.94 120.303 187.639 118.433 187.134 117.765C187.025 117.62 187 117.439 187 117.258V110H174.478C169.243 110.001 164.118 111.511 159.718 114.351L130.481 133.219L132.304 127.297C132.756 125.828 131.446 124.366 129.921 124.555C124.772 125.191 115.047 125.556 109.873 120.36C108.776 119.258 106.763 119.167 105.936 120.484C102.527 125.916 95.1757 137.75 89.7055 147.406C82.3499 160.39 76.5305 176.984 90.5098 185.351C104.489 193.719 117.978 191.441 133.578 180.063C149.178 168.685 161.202 158.249 161.202 158.249V198.89C161.202 201.099 162.993 202.891 165.202 202.891H234.616C236.825 202.891 238.616 201.099 238.616 198.89V158.249C238.616 158.249 250.641 168.685 266.241 180.063C281.841 191.441 295.329 193.719 309.309 185.351C323.288 176.984 317.468 160.39 310.113 147.406C304.643 137.75 297.291 125.916 293.882 120.484C293.055 119.167 291.042 119.258 289.945 120.36C284.771 125.556 275.046 125.191 269.897 124.555C268.373 124.366 267.063 125.828 267.515 127.297L269.337 133.219L240.1 114.351C235.7 111.511 230.576 110.001 225.34 110H212.818V117.258C212.818 117.439 212.793 117.62 212.684 117.765C212.18 118.433 209.878 120.303 199.909 120.335Z"
        fill="#3164FA"
      />
      <path
        d="M292.793 74.8963C292.851 75.3344 292.542 75.7359 292.104 75.7931C287.796 76.3549 284.341 79.1341 281.746 82.5007C279.154 85.8632 277.495 89.7194 276.768 92.2228C276.645 92.6472 276.201 92.8913 275.777 92.7681C275.353 92.6449 275.109 92.2011 275.232 91.7768C276.005 89.1136 277.746 85.0697 280.479 81.5239C283.209 77.9821 287.004 74.8447 291.897 74.2065C292.335 74.1494 292.736 74.4582 292.793 74.8963Z"
        fill="#001F5C"
      />
      <path
        d="M298.741 81.1969C298.908 81.6058 298.712 82.0729 298.303 82.2402C289.733 85.7461 285.344 92.4654 284.246 95.2898C284.086 95.7015 283.622 95.9055 283.21 95.7454C282.798 95.5853 282.594 95.1216 282.755 94.7098C283.989 91.5342 288.667 84.4535 297.697 80.7594C298.106 80.5921 298.573 80.788 298.741 81.1969Z"
        fill="#001F5C"
      />
      <path
        d="M95.6338 88.711C95.241 88.5086 95.0866 88.0262 95.2889 87.6334C95.4913 87.2407 95.9737 87.0863 96.3665 87.2886C104.792 91.6288 108.314 96.7877 109.947 101.684C110.754 104.108 111.09 106.44 111.349 108.502C111.377 108.73 111.405 108.954 111.432 109.174C111.654 110.976 111.842 112.495 112.259 113.747C112.97 115.878 114.561 117.231 116.354 118.002C118.164 118.782 120.106 118.933 121.361 118.712C121.796 118.635 122.211 118.926 122.288 119.361C122.365 119.796 122.074 120.211 121.639 120.288C120.061 120.566 117.803 120.368 115.721 119.472C113.622 118.569 111.631 116.921 110.741 114.253C110.269 112.838 110.062 111.146 109.845 109.379C109.817 109.154 109.79 108.928 109.761 108.701C109.504 106.654 109.183 104.454 108.429 102.19C106.936 97.7119 103.709 92.8708 95.6338 88.711Z"
        fill="#001F5C"
      />
      <path
        d="M303.866 88.711C304.259 88.5086 304.414 88.0262 304.211 87.6334C304.009 87.2407 303.527 87.0863 303.134 87.2886C294.709 91.6288 291.186 96.7877 289.554 101.684C288.746 104.108 288.41 106.44 288.152 108.502C288.123 108.73 288.095 108.954 288.068 109.174C287.846 110.976 287.658 112.495 287.241 113.747C286.531 115.878 284.939 117.231 283.146 118.002C281.336 118.782 279.394 118.933 278.139 118.712C277.704 118.635 277.289 118.926 277.212 119.361C277.136 119.796 277.426 120.211 277.861 120.288C279.44 120.566 281.697 120.368 283.779 119.472C285.878 118.569 287.87 116.921 288.759 114.253C289.231 112.838 289.438 111.146 289.655 109.38C289.683 109.155 289.711 108.928 289.739 108.701C289.996 106.654 290.317 104.454 291.072 102.19C292.564 97.7119 295.792 92.8708 303.866 88.711Z"
        fill="#001F5C"
      />
      <path
        d="M101.197 82.2402C100.788 82.0729 100.592 81.6058 100.76 81.1969C100.927 80.788 101.394 80.5921 101.803 80.7594C110.833 84.4535 115.511 91.5342 116.746 94.7098C116.906 95.1216 116.702 95.5853 116.29 95.7454C115.878 95.9055 115.415 95.7015 115.255 95.2898C114.156 92.4654 109.767 85.7461 101.197 82.2402Z"
        fill="#001F5C"
      />
      <path
        d="M107.397 75.7931C106.959 75.7359 106.65 75.3344 106.707 74.8963C106.764 74.4582 107.165 74.1494 107.604 74.2065C112.496 74.8447 116.291 77.9821 119.021 81.5239C121.754 85.0697 123.495 89.1136 124.268 91.7768C124.392 92.2011 124.147 92.6449 123.723 92.7681C123.299 92.8913 122.855 92.6472 122.732 92.2228C122.005 89.7194 120.346 85.8632 117.754 82.5007C115.159 79.1341 111.704 76.3549 107.397 75.7931Z"
        fill="#001F5C"
      />
      <path
        d="M269.313 80.6025C271.467 80.773 273.346 81.6026 274.444 82.3344C274.812 82.5794 274.911 83.0761 274.666 83.4438C274.421 83.8114 273.924 83.9107 273.556 83.6656C272.654 83.0641 271.033 82.3436 269.187 82.1975C267.364 82.0532 265.344 82.4672 263.535 84.0946C261.701 85.7451 260.763 87.9421 260.348 90.066C259.987 91.9119 260.031 93.6568 260.199 94.8404C261.108 94.9975 262.221 94.9094 263.451 94.2863C264.92 93.5426 266.611 92.008 268.309 89.0969C268.532 88.7153 269.022 88.5864 269.403 88.809C269.785 89.0316 269.914 89.5215 269.691 89.9031C267.889 92.992 265.997 94.7908 264.174 95.7138C263.025 96.2956 261.925 96.5178 260.932 96.5079C262.007 97.1436 262.968 98.0119 263.665 99.0555C263.911 99.4229 263.812 99.9197 263.444 100.165C263.077 100.41 262.58 100.312 262.335 99.9441C261.449 98.617 259.987 97.6083 258.467 97.1655C257.078 96.7612 255.76 96.8567 254.805 97.4617C254.736 99.904 255.445 104.187 257.649 108.293C259.955 112.59 263.869 116.643 270.194 118.224C270.623 118.331 270.883 118.765 270.776 119.194C270.669 119.622 270.235 119.883 269.806 119.776C262.951 118.063 258.709 113.651 256.239 109.049C253.783 104.473 253.048 99.6575 253.225 96.9857C253.24 96.7627 253.347 96.556 253.521 96.4157C254.972 95.2466 256.916 95.1035 258.699 95.5698C258.441 94.217 258.329 92.0554 258.778 89.759C259.237 87.4079 260.299 84.8549 262.465 82.9054C264.657 80.9328 267.136 80.4301 269.313 80.6025Z"
        fill="#001F5C"
      />
      <path
        d="M125.056 82.3344C126.154 81.6026 128.033 80.773 130.187 80.6025C132.364 80.4301 134.843 80.9328 137.035 82.9054C139.201 84.8549 140.263 87.4079 140.723 89.759C141.172 92.0553 141.059 94.217 140.802 95.5698C142.584 95.1035 144.529 95.2466 145.979 96.4157C146.153 96.556 146.26 96.7627 146.275 96.9857C146.452 99.6575 145.717 104.473 143.261 109.049C140.791 113.651 136.549 118.063 129.694 119.776C129.265 119.883 128.831 119.622 128.724 119.194C128.617 118.765 128.878 118.331 129.306 118.224C135.632 116.643 139.545 112.59 141.851 108.293C144.055 104.187 144.764 99.904 144.696 97.4617C143.74 96.8567 142.422 96.7612 141.033 97.1655C139.513 97.6083 138.052 98.617 137.165 99.9441C136.92 100.312 136.423 100.41 136.056 100.165C135.688 99.9197 135.589 99.4229 135.835 99.0555C136.532 98.0119 137.493 97.1436 138.568 96.5079C137.575 96.5178 136.475 96.2956 135.326 95.7138C133.503 94.7908 131.611 92.992 129.809 89.9031C129.586 89.5215 129.715 89.0316 130.097 88.809C130.479 88.5864 130.969 88.7153 131.191 89.0969C132.889 92.008 134.58 93.5426 136.049 94.2863C137.28 94.9094 138.392 94.9975 139.301 94.8404C139.469 93.6568 139.513 91.9119 139.152 90.066C138.737 87.9421 137.799 85.7451 135.965 84.0946C134.157 82.4672 132.136 82.0532 130.313 82.1975C128.467 82.3436 126.846 83.0641 125.944 83.6656C125.576 83.9107 125.08 83.8114 124.834 83.4438C124.589 83.0761 124.689 82.5794 125.056 82.3344Z"
        fill="#001F5C"
      />
    </svg>
  );
};
