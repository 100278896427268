import React from 'react';
import {type IconType} from '../../icons';

export const SmartContract: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M92.8693 63.6387C92.8693 64.0805 92.5112 64.4387 92.0693 64.4387H81.0406C80.5988 64.4387 80.2406 64.0805 80.2406 63.6387C80.2406 63.1968 80.5988 62.8387 81.0406 62.8387H92.0693C92.5112 62.8387 92.8693 63.1968 92.8693 63.6387Z"
        fill="#001F5C"
      />
      <path
        d="M107.509 75.4299C107.951 75.4299 108.309 75.0717 108.309 74.6299C108.309 74.1881 107.951 73.8299 107.509 73.8299H81.04C80.5982 73.8299 80.24 74.1881 80.24 74.6299C80.24 75.0717 80.5982 75.4299 81.04 75.4299H107.509Z"
        fill="#001F5C"
      />
      <path
        d="M108.309 85.6211C108.309 86.0629 107.951 86.4211 107.509 86.4211H81.0403C80.5984 86.4211 80.2403 86.0629 80.2403 85.6211C80.2403 85.1793 80.5984 84.8211 81.0403 84.8211H107.509C107.951 84.8211 108.309 85.1793 108.309 85.6211Z"
        fill="#001F5C"
      />
      <path
        d="M107.509 97.4123C107.951 97.4123 108.309 97.0541 108.309 96.6123C108.309 96.1705 107.951 95.8123 107.509 95.8123H96.4803C96.0384 95.8123 95.6803 96.1705 95.6803 96.6123C95.6803 97.0541 96.0384 97.4123 96.4803 97.4123H107.509Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0107 48.251C70.9062 48.251 70.0107 49.1464 70.0107 50.251V110C70.0107 111.104 70.9062 112 72.0107 112H116.537C117.642 112 118.537 111.104 118.537 110V50.251C118.537 49.1464 117.642 48.251 116.537 48.251H72.0107ZM116.537 49.851H72.0107C71.7898 49.851 71.6107 50.0301 71.6107 50.251V110C71.6107 110.221 71.7898 110.4 72.0107 110.4H116.537C116.758 110.4 116.937 110.221 116.937 110V50.251C116.937 50.0301 116.758 49.851 116.537 49.851Z"
        fill="#001F5C"
      />
      <path
        d="M55.5741 57.2401C57.9617 53.0319 54.872 48 50.1764 48C46.7381 48 43.9521 50.7765 43.9521 54.2031C43.9521 58.111 47.5846 61.1679 51.6005 60.2275L55.6538 65.6167C56.1243 66.242 56.8613 66.6092 57.6455 66.6092H62.6334C64.0038 66.6092 65.1147 65.4983 65.1147 64.128C65.1147 62.7576 64.0038 61.6467 62.6334 61.6467H58.8904L55.5741 57.2401Z"
        fill="#3164FA"
      />
      <path
        d="M47.6867 85.2181C44.2484 85.2181 41.4624 82.4416 41.4624 79.0151C41.4624 75.5885 44.2484 72.812 47.6867 72.812C50.2386 72.812 52.4246 74.3454 53.3856 76.5338H62.6334C64.0037 76.5338 65.1146 77.6447 65.1146 79.0151C65.1146 80.3854 64.0038 81.4963 62.6334 81.4963H53.3856C52.4246 83.6847 50.2386 85.2181 47.6867 85.2181Z"
        fill="#3164FA"
      />
      <path
        d="M50.1764 110.031C46.7381 110.031 43.9521 107.254 43.9521 103.828C43.9521 99.9171 47.5871 96.8627 51.598 97.8031L55.6538 92.4139C56.1243 91.7886 56.8613 91.4214 57.6455 91.4214H62.6334C64.0038 91.4214 65.1147 92.5323 65.1147 93.9026C65.1147 95.273 64.0038 96.3838 62.6334 96.3838H58.8904L55.5716 100.79C57.9717 105.014 54.8521 110.031 50.1764 110.031Z"
        fill="#3164FA"
      />
    </svg>
  );
};
