import React from 'react';
import {type IconType} from '../../icons';

export const Database: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.6226 28.1997C82.9528 28.1997 85.6627 30.8808 85.6627 34.2007C85.6627 37.5206 82.9528 40.2016 79.6226 40.2016C76.2924 40.2016 73.5825 37.5206 73.5825 34.2007C73.5825 30.8808 76.2924 28.1997 79.6226 28.1997ZM84.0627 34.2007C84.0627 31.7757 82.0804 29.7997 79.6226 29.7997C77.1647 29.7997 75.1825 31.7757 75.1825 34.2007C75.1825 36.6256 77.1647 38.6016 79.6226 38.6016C82.0804 38.6016 84.0627 36.6256 84.0627 34.2007Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1214 68.2104C44.4516 68.2104 47.1614 70.8915 47.1614 74.2114C47.1614 77.5313 44.4516 80.2124 41.1214 80.2124C37.7912 80.2124 35.0813 77.5313 35.0813 74.2114C35.0813 70.8915 37.7912 68.2104 41.1214 68.2104ZM45.5614 74.2114C45.5614 71.7865 43.5792 69.8104 41.1214 69.8104C38.6635 69.8104 36.6813 71.7865 36.6813 74.2114C36.6813 76.6364 38.6635 78.6124 41.1214 78.6124C43.5792 78.6124 45.5614 76.6364 45.5614 74.2114Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.878 68.2104C122.209 68.2104 124.919 70.8915 124.919 74.2114C124.919 77.5313 122.209 80.2124 118.878 80.2124C115.548 80.2124 112.838 77.5313 112.838 74.2114C112.838 70.8915 115.548 68.2104 118.878 68.2104ZM123.319 74.2114C123.319 71.7865 121.336 69.8104 118.878 69.8104C116.421 69.8104 114.438 71.7865 114.438 74.2114C114.438 76.6364 116.421 78.6124 118.878 78.6124C121.336 78.6124 123.319 76.6364 123.319 74.2114Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.904 90.9886C110.247 90.9886 108.86 89.6147 109.357 88.0343C110.623 84.0104 114.407 81.09 118.878 81.09C123.35 81.09 127.134 84.0104 128.4 88.0343C128.897 89.6147 127.51 90.9886 125.853 90.9886H111.904ZM126.75 89.028C126.575 89.2205 126.251 89.3886 125.853 89.3886H111.904C111.506 89.3886 111.182 89.2205 111.007 89.028C110.857 88.8637 110.823 88.7072 110.883 88.5145C111.944 85.1445 115.119 82.69 118.878 82.69C122.638 82.69 125.813 85.1445 126.873 88.5145C126.934 88.7072 126.899 88.8637 126.75 89.028Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6 88.0343C31.1027 89.6147 32.49 90.9886 34.1469 90.9886H48.0958C49.7526 90.9886 51.1399 89.6147 50.6426 88.0343C49.3765 84.0104 45.5925 81.09 41.1213 81.09C36.6501 81.09 32.8661 84.0104 31.6 88.0343ZM48.0958 89.3886C48.4939 89.3886 48.818 89.2205 48.993 89.028C49.1423 88.8637 49.177 88.7072 49.1164 88.5145C48.056 85.1445 44.8807 82.69 41.1213 82.69C37.362 82.69 34.1866 85.1445 33.1263 88.5145C33.0656 88.7072 33.1003 88.8637 33.2497 89.028C33.4246 89.2205 33.7488 89.3886 34.1469 89.3886H48.0958Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6481 50.9779C70.9912 50.9779 69.604 49.604 70.1013 48.0235C71.3673 43.9997 75.1513 41.0793 79.6225 41.0793C84.0937 41.0793 87.8777 43.9997 89.1438 48.0235C89.6411 49.604 88.2538 50.9779 86.597 50.9779H72.6481ZM87.4942 49.0172C87.3192 49.2097 86.9951 49.3779 86.597 49.3779H72.6481C72.25 49.3779 71.9259 49.2097 71.7509 49.0172C71.6016 48.8529 71.5669 48.6965 71.6275 48.5038C72.6879 45.1337 75.8632 42.6793 79.6225 42.6793C83.3819 42.6793 86.5572 45.1337 87.6176 48.5038C87.6782 48.6965 87.6435 48.8529 87.4942 49.0172Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6624 114.222C85.6624 110.902 82.9525 108.221 79.6224 108.221C76.2922 108.221 73.5823 110.902 73.5823 114.222C73.5823 117.542 76.2922 120.223 79.6224 120.223C82.9525 120.223 85.6624 117.542 85.6624 114.222ZM79.6224 109.821C82.0802 109.821 84.0624 111.797 84.0624 114.222C84.0624 116.647 82.0802 118.623 79.6224 118.623C77.1645 118.623 75.1823 116.647 75.1823 114.222C75.1823 111.797 77.1645 109.821 79.6224 109.821Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6479 130.999C70.991 130.999 69.6037 129.625 70.101 128.045C71.3671 124.021 75.1511 121.101 79.6223 121.101C84.0935 121.101 87.8775 124.021 89.1436 128.045C89.6409 129.625 88.2536 130.999 86.5967 130.999H72.6479ZM87.4939 129.039C87.319 129.231 86.9948 129.399 86.5967 129.399H72.6479C72.2497 129.399 71.9256 129.231 71.7506 129.039C71.6013 128.874 71.5666 128.718 71.6272 128.525C72.6876 125.155 75.8629 122.701 79.6223 122.701C83.3816 122.701 86.557 125.155 87.6173 128.525C87.678 128.718 87.6433 128.874 87.4939 129.039Z"
        fill="#001F5C"
      />
      <path
        d="M71.5615 40.4096C71.6602 40.8402 71.3911 41.2694 70.9604 41.368C58.1343 44.3074 47.6819 53.4795 42.9641 65.5274C42.803 65.9388 42.3389 66.1417 41.9275 65.9806C41.516 65.8195 41.3131 65.3554 41.4742 64.944C46.3825 52.4099 57.2547 42.8675 70.603 39.8085C71.0337 39.7098 71.4628 39.9789 71.5615 40.4096Z"
        fill="#3164FA"
      />
      <path
        d="M88.1201 40.4096C88.2188 39.9789 88.6479 39.7098 89.0786 39.8085C102.427 42.8675 113.299 52.4099 118.207 64.944C118.369 65.3554 118.166 65.8195 117.754 65.9806C117.343 66.1417 116.879 65.9388 116.718 65.5274C112 53.4795 101.547 44.3074 88.7212 41.368C88.2905 41.2694 88.0214 40.8402 88.1201 40.4096Z"
        fill="#3164FA"
      />
      <path
        d="M71.5615 119.504C71.6602 119.073 71.3911 118.644 70.9604 118.545C58.1343 115.606 47.6819 106.434 42.9641 94.3859C42.803 93.9745 42.3389 93.7716 41.9275 93.9327C41.516 94.0938 41.3131 94.5579 41.4742 94.9693C46.3825 107.503 57.2547 117.046 70.603 120.105C71.0337 120.204 71.4628 119.934 71.5615 119.504Z"
        fill="#3164FA"
      />
      <path
        d="M88.1201 119.504C88.2188 119.934 88.6479 120.204 89.0786 120.105C102.427 117.046 113.299 107.503 118.207 94.9693C118.369 94.5579 118.166 94.0938 117.754 93.9327C117.343 93.7716 116.879 93.9745 116.718 94.3859C112 106.434 101.547 115.606 88.7212 118.545C88.2905 118.644 88.0214 119.073 88.1201 119.504Z"
        fill="#3164FA"
      />
      <path
        d="M79.4636 60.7059C69.456 60.7059 61.3456 64.8631 61.3456 69.9915C61.3456 75.12 69.4572 79.2771 79.4636 79.2771C89.4712 79.2771 97.5816 75.1192 97.5816 69.9915C97.5816 64.8629 89.4707 60.7059 79.4636 60.7059Z"
        fill="#3164FA"
      />
      <path
        d="M62.4769 76.5602C64.969 80.1619 71.5799 82.9436 79.4625 82.9436C87.3847 82.9436 93.9866 80.1901 96.4488 76.5595C97.1546 77.5516 97.5811 78.537 97.5811 79.6544C97.5811 84.7828 89.4707 88.9399 79.4631 88.9399C69.4567 88.9399 61.3451 84.783 61.3451 79.6544C61.3451 78.5123 61.7401 77.5711 62.4769 76.5602Z"
        fill="#3164FA"
      />
      <path
        d="M79.4625 92.0027C71.5799 92.0027 64.9695 89.2202 62.4775 85.6185C61.7407 86.6294 61.3451 87.5714 61.3451 88.7134C61.3451 93.842 69.4567 97.999 79.4631 97.999C89.4707 97.999 97.5811 93.8419 97.5811 88.7134C97.5811 87.5961 97.1546 86.6107 96.4488 85.6185C93.9866 89.2492 87.3847 92.0027 79.4625 92.0027Z"
        fill="#3164FA"
      />
    </svg>
  );
};
