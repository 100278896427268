import React from 'react';
import {type IconType} from '..';

export const IconLinkExternal: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.63336 3.6672H10.4934C10.5273 3.66334 10.5616 3.67001 10.5916 3.6863C10.6217 3.70259 10.6459 3.72771 10.6612 3.75826C10.6765 3.78882 10.682 3.82332 10.677 3.85712C10.6721 3.89091 10.6568 3.92235 10.6334 3.9472L1.96669 12.6139C1.79005 12.8034 1.69389 13.0542 1.69846 13.3132C1.70303 13.5723 1.80798 13.8195 1.9912 14.0027C2.17441 14.1859 2.42159 14.2909 2.68066 14.2954C2.93973 14.3 3.19046 14.2038 3.38003 14.0272L12.0467 5.36053C12.0712 5.33744 12.1019 5.32199 12.1351 5.31607C12.1682 5.31015 12.2024 5.31402 12.2334 5.3272C12.2632 5.34021 12.2885 5.36166 12.3063 5.3889C12.3241 5.41615 12.3335 5.448 12.3334 5.48053V13.3339C12.3334 13.5991 12.4387 13.8534 12.6263 14.041C12.8138 14.2285 13.0681 14.3339 13.3334 14.3339C13.5986 14.3339 13.8529 14.2285 14.0405 14.041C14.228 13.8534 14.3334 13.5991 14.3334 13.3339V3.33386C14.3343 3.1119 14.2907 2.892 14.2052 2.68717C14.1197 2.48234 13.994 2.29674 13.8355 2.14135C13.677 1.98596 13.4889 1.86394 13.2825 1.78249C13.076 1.70105 12.8553 1.66184 12.6334 1.6672H2.63336C2.36814 1.6672 2.11379 1.77255 1.92625 1.96009C1.73872 2.14762 1.63336 2.40198 1.63336 2.6672C1.63336 2.93241 1.73872 3.18677 1.92625 3.3743C2.11379 3.56184 2.36814 3.6672 2.63336 3.6672Z"
        fill="currentColor"
      />
    </svg>
  );
};
