import React from 'react';
import {type IconType} from '../../icons';

export const NotFound: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8194 58.9865C30.8194 39.0484 46.984 22.8838 66.9221 22.8838C86.8603 22.8838 103.025 39.0484 103.025 58.9865C103.025 78.9247 86.8603 95.0893 66.9221 95.0893C59.8446 95.0893 53.2538 93.0357 47.6824 89.5177L31.5709 105.629C28.8774 108.323 24.5102 108.323 21.8166 105.629C19.123 102.936 19.123 98.5684 21.8166 95.8748L37.645 80.0463C33.37 74.1179 30.8194 66.8574 30.8194 58.9865ZM22.8772 104.568C24.9851 106.676 28.4025 106.676 30.5103 104.568L47.0492 88.0296C47.3004 87.7784 47.6934 87.7379 47.9905 87.9325C53.4346 91.4987 59.9298 93.5893 66.9221 93.5893C86.0319 93.5893 101.525 78.0963 101.525 58.9865C101.525 39.8768 86.0319 24.3838 66.9221 24.3838C47.8124 24.3838 32.3194 39.8768 32.3194 58.9865C32.3194 66.7508 34.9088 73.8957 39.2312 79.672C39.4546 79.9706 39.4247 80.388 39.161 80.6517L22.8772 96.9355C20.7694 99.0433 20.7694 102.461 22.8772 104.568ZM66.9221 33.6062C52.9278 33.6062 41.5418 44.9922 41.5418 58.9865C41.5418 72.9809 52.9278 84.3668 66.9221 84.3668C80.9165 84.3668 92.3024 72.9809 92.3024 58.9865C92.3024 44.9922 80.9165 33.6062 66.9221 33.6062ZM40.0418 58.9865C40.0418 44.1638 52.0994 32.1062 66.9221 32.1062C81.7449 32.1062 93.8024 44.1638 93.8024 58.9865C93.8024 73.8093 81.7449 85.8668 66.9221 85.8668C52.0994 85.8668 40.0418 73.8093 40.0418 58.9865Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8194 58.9865C30.8194 39.0484 46.984 22.8838 66.9221 22.8838C86.8603 22.8838 103.025 39.0484 103.025 58.9865C103.025 78.9247 86.8603 95.0893 66.9221 95.0893C59.8446 95.0893 53.2538 93.0357 47.6824 89.5177L31.5709 105.629C28.8773 108.323 24.5102 108.323 21.8166 105.629C19.123 102.936 19.123 98.5684 21.8166 95.8748L37.645 80.0464C33.37 74.1179 30.8194 66.8574 30.8194 58.9865ZM22.8772 104.568C24.9851 106.676 28.4025 106.676 30.5103 104.568L47.0492 88.0296C47.3004 87.7784 47.6934 87.7379 47.9905 87.9325C53.4346 91.4987 59.9298 93.5893 66.9221 93.5893C86.0319 93.5893 101.525 78.0963 101.525 58.9865C101.525 39.8768 86.0319 24.3838 66.9221 24.3838C47.8124 24.3838 32.3194 39.8768 32.3194 58.9865C32.3194 66.7508 34.9088 73.8957 39.2312 79.672C39.4546 79.9706 39.4247 80.388 39.161 80.6517L22.8772 96.9355C20.7694 99.0433 20.7694 102.461 22.8772 104.568ZM66.9221 33.6062C52.9278 33.6062 41.5418 44.9922 41.5418 58.9865C41.5418 72.9809 52.9278 84.3668 66.9221 84.3668C80.9165 84.3668 92.3024 72.9809 92.3024 58.9865C92.3024 44.9922 80.9165 33.6062 66.9221 33.6062ZM40.0418 58.9865C40.0418 44.1638 52.0994 32.1062 66.9221 32.1062C81.7449 32.1062 93.8024 44.1638 93.8024 58.9865C93.8024 73.8093 81.7449 85.8668 66.9221 85.8668C52.0994 85.8668 40.0418 73.8093 40.0418 58.9865Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6545 128.099C64.6545 124.776 67.3485 122.082 70.6717 122.082H127.733C131.056 122.082 133.75 124.776 133.75 128.099C133.75 131.422 131.056 134.116 127.733 134.116H70.6717C67.3485 134.116 64.6545 131.422 64.6545 128.099ZM132.25 128.099C132.25 125.604 130.228 123.582 127.733 123.582H70.6717C68.1769 123.582 66.1545 125.604 66.1545 128.099C66.1545 130.594 68.1769 132.616 70.6717 132.616H127.733C130.228 132.616 132.25 130.594 132.25 128.099Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.6545 107.909C64.6545 104.586 67.3485 101.892 70.6717 101.892H127.733C131.056 101.892 133.75 104.586 133.75 107.909C133.75 111.232 131.056 113.926 127.733 113.926H70.6717C67.3485 113.926 64.6545 111.232 64.6545 107.909ZM132.25 107.909C132.25 105.414 130.228 103.392 127.733 103.392H70.6717C68.1769 103.392 66.1545 105.414 66.1545 107.909C66.1545 110.404 68.1769 112.426 70.6717 112.426H127.733C130.228 112.426 132.25 110.404 132.25 107.909Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.525 87.7179C101.525 84.3947 104.219 81.7007 107.542 81.7007H127.733C131.056 81.7007 133.75 84.3947 133.75 87.7179C133.75 91.0411 131.056 93.735 127.733 93.735H107.542C104.219 93.735 101.525 91.0411 101.525 87.7179ZM132.25 87.7179C132.25 85.2231 130.228 83.2007 127.733 83.2007H107.542C105.047 83.2007 103.025 85.2231 103.025 87.7179C103.025 90.2126 105.047 92.235 107.542 92.235H127.733C130.228 92.235 132.25 90.2126 132.25 87.7179Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4728 65.1598C82.6209 67.3079 82.6209 70.7907 80.4728 72.9388C78.3247 75.0869 74.8419 75.0869 72.6938 72.9388L66.7215 66.9664L60.7491 72.9388C58.601 75.0869 55.1183 75.0869 52.9702 72.9388C50.8221 70.7907 50.8221 67.3079 52.9702 65.1598L58.9425 59.1874L52.97 53.2148C50.8219 51.0667 50.8219 47.5839 52.97 45.4358C55.1181 43.2877 58.6008 43.2877 60.7489 45.4358L66.7215 51.4084L72.694 45.4358C74.8421 43.2877 78.3249 43.2877 80.473 45.4358C82.6211 47.5839 82.6211 51.0667 80.473 53.2148L74.5004 59.1874L80.4728 65.1598Z"
        fill="#3164FA"
      />
    </svg>
  );
};
