import React from 'react';
import {type IconType} from '..';

export const IconClock: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_230_11)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.55544 14.6518C4.87104 15.5308 6.41775 16 8 16C10.121 15.9975 12.1543 15.1538 13.6541 13.6541C15.1538 12.1543 15.9975 10.121 16 8C16 6.41775 15.5308 4.87104 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346627 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346627 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518ZM4.88881 3.34377C5.80973 2.72844 6.89243 2.4 8 2.4C9.48522 2.4 10.9096 2.99 11.9598 4.04021C13.01 5.09041 13.6 6.51479 13.6 8C13.6 9.10758 13.2716 10.1903 12.6562 11.1112C12.0409 12.0321 11.1663 12.7499 10.143 13.1737C9.11977 13.5976 7.99379 13.7085 6.9075 13.4924C5.8212 13.2763 4.82338 12.743 4.04021 11.9598C3.25703 11.1766 2.72368 10.1788 2.50761 9.09251C2.29153 8.00622 2.40243 6.88024 2.82628 5.85698C3.25013 4.83371 3.96789 3.95911 4.88881 3.34377ZM9.8448 10.432C10.0092 10.5417 10.2024 10.6002 10.4 10.6C10.6147 10.6001 10.8236 10.5311 10.9961 10.4032C11.1685 10.2754 11.2952 10.0954 11.3574 9.89001C11.4197 9.68458 11.4142 9.46457 11.3418 9.26251C11.2693 9.06045 11.1338 8.88706 10.9552 8.76798L9 7.46558V5.59998C9 5.33476 8.89464 5.08041 8.70711 4.89287C8.51957 4.70533 8.26522 4.59998 8 4.59998C7.73478 4.59998 7.48043 4.70533 7.29289 4.89287C7.10536 5.08041 7 5.33476 7 5.59998V7.99998C6.99981 8.16458 7.04032 8.32667 7.11793 8.47183C7.19553 8.61699 7.30782 8.74071 7.4448 8.83198L9.8448 10.432Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_230_11">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
