import React from 'react';
import {type IconType} from '../../../icons';

export const SmallWeirdTwo: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.75 80.2162C194.75 82.9563 190.983 83.7096 189.929 81.1804C189.585 80.3543 188.415 80.3543 188.071 81.1804C187.017 83.7096 183.25 82.9563 183.25 80.2162L183.25 76.5061C183.184 76.4717 183.122 76.4267 183.066 76.3712L177.47 70.7745C177.177 70.4816 177.177 70.0067 177.47 69.7138C177.763 69.4209 178.237 69.4209 178.53 69.7138L183.25 74.4334V73.1667C183.25 71.0036 185.004 69.25 187.167 69.25H190.833C192.996 69.25 194.75 71.0036 194.75 73.1667V75.0908L203.25 75.0908V73.3031C203.25 71.0646 205.065 69.25 207.303 69.25H210.697C212.935 69.25 214.75 71.0646 214.75 73.3031V74.715L220.588 70.8735C220.934 70.6458 221.399 70.7417 221.627 71.0877C221.854 71.4337 221.758 71.8988 221.412 72.1265L214.816 76.4674C214.794 76.4815 214.772 76.4944 214.75 76.5061V81.1296C214.75 83.8734 210.974 84.6204 209.929 82.0833C209.587 81.2537 208.413 81.2537 208.071 82.0833C207.026 84.6204 203.25 83.8734 203.25 81.1296V76.5908L194.75 76.5908L194.75 80.2162ZM191.314 80.6034C191.737 81.6192 193.25 81.3167 193.25 80.2162L193.25 73.1667C193.25 71.832 192.168 70.75 190.833 70.75L187.167 70.75C185.832 70.75 184.75 71.832 184.75 73.1667L184.75 80.2162C184.75 81.3167 186.263 81.6192 186.686 80.6034C187.543 78.5466 190.457 78.5466 191.314 80.6034ZM204.75 73.3031C204.75 71.893 205.893 70.75 207.303 70.75H210.697C212.107 70.75 213.25 71.893 213.25 73.3031V81.1296C213.25 82.2303 211.735 82.5299 211.316 81.5122C210.465 79.4441 207.535 79.4441 206.684 81.5122C206.265 82.5299 204.75 82.2303 204.75 81.1296V73.3031Z"
        fill="#001F5C"
      />
    </svg>
  );
};
