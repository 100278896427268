import React from 'react';
import {type IconType} from '../../../icons';

export const Informal: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M172.235 72.8062C172.235 72.8062 172.254 53.6528 188.954 42.4726C205.655 31.2925 218.646 35.3473 222.083 48.8741C225.519 62.4008 225.234 72.1331 225.234 72.1331C225.234 72.1331 222.484 70.6222 218.96 74.2296L218.278 66.4556C218.278 66.4556 214.566 64.7434 213.452 58.6104C201.205 65.1908 194.802 63.2102 187.657 59.2408C184.036 65.9147 179.767 67.086 179.767 67.086L179.863 75.1101C179.863 75.1101 177.806 71.5454 172.235 72.8062Z"
        fill="#001F5C"
      />
      <path
        d="M212.155 103.693C212.155 103.693 217.349 103.961 218.927 99.4551C221.71 102.339 223.95 102.879 225.699 98.9142C227.449 94.9489 225.699 83.053 225.699 83.053C225.699 83.053 224.218 86.9023 217.538 85.0071C215.403 94.0684 212.155 96.8014 212.155 96.8014V103.693Z"
        fill="#001F5C"
      />
      <path
        d="M180.885 83.4964C180.885 83.4964 177.536 87.9131 172.066 83.0449C170.676 93.1432 166.872 103.063 175.314 103.603C180.512 103.603 181.441 99.6341 181.441 99.6341C181.441 99.6341 181.902 104.323 186.636 104.775V97.0191C183.444 93.129 181.45 88.4408 180.885 83.4964Z"
        fill="#001F5C"
      />
    </svg>
  );
};
