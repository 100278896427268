import React from 'react';
import {type IconType} from '../../icons';

export const App: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M129.5 58V58.8C129.942 58.8 130.3 58.4418 130.3 58H129.5ZM30.5 58H29.7C29.7 58.4418 30.0582 58.8 30.5 58.8V58ZM36.5 45.8H123.5V44.2H36.5V45.8ZM128.7 51V109H130.3V51H128.7ZM123.5 114.2H36.5V115.8H123.5V114.2ZM31.3 109V51H29.7V109H31.3ZM36.5 114.2C33.6281 114.2 31.3 111.872 31.3 109H29.7C29.7 112.756 32.7445 115.8 36.5 115.8V114.2ZM128.7 109C128.7 111.872 126.372 114.2 123.5 114.2V115.8C127.256 115.8 130.3 112.756 130.3 109H128.7ZM123.5 45.8C126.372 45.8 128.7 48.1281 128.7 51H130.3C130.3 47.2445 127.256 44.2 123.5 44.2V45.8ZM36.5 44.2C32.7445 44.2 29.7 47.2445 29.7 51H31.3C31.3 48.1281 33.6281 45.8 36.5 45.8V44.2ZM128.7 51V58H130.3V51H128.7ZM129.5 57.2H30.5V58.8H129.5V57.2ZM31.3 58V51H29.7V58H31.3ZM122.7 51.5C122.7 52.4389 121.939 53.2 121 53.2V54.8C122.823 54.8 124.3 53.3225 124.3 51.5H122.7ZM121 53.2C120.061 53.2 119.3 52.4389 119.3 51.5H117.7C117.7 53.3225 119.177 54.8 121 54.8V53.2ZM119.3 51.5C119.3 50.5611 120.061 49.8 121 49.8V48.2C119.177 48.2 117.7 49.6775 117.7 51.5H119.3ZM121 49.8C121.939 49.8 122.7 50.5611 122.7 51.5H124.3C124.3 49.6775 122.823 48.2 121 48.2V49.8ZM112.7 51.5C112.7 52.4389 111.939 53.2 111 53.2V54.8C112.823 54.8 114.3 53.3225 114.3 51.5H112.7ZM111 53.2C110.061 53.2 109.3 52.4389 109.3 51.5H107.7C107.7 53.3225 109.177 54.8 111 54.8V53.2ZM109.3 51.5C109.3 50.5611 110.061 49.8 111 49.8V48.2C109.177 48.2 107.7 49.6775 107.7 51.5H109.3ZM111 49.8C111.939 49.8 112.7 50.5611 112.7 51.5H114.3C114.3 49.6775 112.823 48.2 111 48.2V49.8ZM102.7 51.5C102.7 52.4389 101.939 53.2 101 53.2V54.8C102.823 54.8 104.3 53.3225 104.3 51.5H102.7ZM101 53.2C100.061 53.2 99.3 52.4389 99.3 51.5H97.7C97.7 53.3225 99.1775 54.8 101 54.8V53.2ZM99.3 51.5C99.3 50.5611 100.061 49.8 101 49.8V48.2C99.1775 48.2 97.7 49.6775 97.7 51.5H99.3ZM101 49.8C101.939 49.8 102.7 50.5611 102.7 51.5H104.3C104.3 49.6775 102.823 48.2 101 48.2V49.8Z"
        fill="#001F5C"
      />
      <path
        d="M54.5 77C59.1944 77 63 80.8056 63 85.5C63 90.1944 59.1944 94 54.5 94C49.8056 94 46 90.1944 46 85.5C46 84.3954 45.1046 83.5 44 83.5C42.8954 83.5 42 84.3954 42 85.5C42 92.4036 47.5964 98 54.5 98C61.4036 98 67 92.4036 67 85.5C67 78.5964 61.4036 73 54.5 73C53.3954 73 52.5 73.8954 52.5 75C52.5 76.1046 53.3954 77 54.5 77Z"
        fill="#3164FA"
      />
      <path
        d="M76 84C74.8954 84 74 84.8954 74 86C74 87.1046 74.8954 88 76 88H114C115.105 88 116 87.1046 116 86C116 84.8954 115.105 84 114 84H76Z"
        fill="#3164FA"
      />
      <path
        d="M74 93C74 91.8954 74.8954 91 76 91H82C83.1046 91 84 91.8954 84 93C84 94.1046 83.1046 95 82 95H76C74.8954 95 74 94.1046 74 93Z"
        fill="#3164FA"
      />
      <path
        d="M76 77C74.8954 77 74 77.8954 74 79C74 80.1046 74.8954 81 76 81H114C115.105 81 116 80.1046 116 79C116 77.8954 115.105 77 114 77H76Z"
        fill="#3164FA"
      />
    </svg>
  );
};
