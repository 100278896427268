import React from 'react';
import {type IconType} from '../../../icons';

export const Voting: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M258.925 71.7016C255.768 70.6963 252.394 72.4413 251.39 75.5993L243.62 100.042C242.616 103.2 244.362 106.575 247.519 107.581L261.74 112.109C264.897 113.114 268.271 111.369 269.275 108.211L277.045 83.7677C278.049 80.6097 276.303 77.2347 273.146 76.2293L258.925 71.7016Z"
        fill="#3164FA"
      />
      <path
        d="M159.701 115.111C155.075 118.192 151.464 122.575 149.321 127.706C148.371 129.986 147.314 132.509 146.188 135.2C137.295 156.435 124.021 188.135 124 193.613C124 202.837 133.017 213.567 144.471 213.567C155.925 213.567 159.785 209.605 159.785 209.605V182.836C154.781 184.132 149.491 176.644 149.491 176.644C149.491 176.644 149.115 171.869 153.308 163.121C156.444 156.575 163.95 151.155 175.206 151.682C182.249 152.839 186.002 158.358 186.002 164.301H230.483V212.699H230.97C231.955 212.61 234.406 212.61 236.88 212.633C240.193 212.663 242.879 210.013 242.879 206.699V157.886C242.879 157.886 254.717 168.159 270.074 179.36C285.432 190.562 298.711 192.804 312.473 184.567C326.235 176.329 320.506 159.993 313.265 147.211C306.023 134.43 292.641 111.695 292.641 111.695C280.764 125.134 270.352 124.548 270.352 124.548L273.123 133.245L244.34 114.67C240.009 111.875 234.964 110.388 229.81 110.387H211.81V117.753C211.81 117.753 211.715 120.562 199.517 120.562C187.319 120.562 187.504 118.367 187.504 118.367V110.387H175.317C169.76 110.386 164.327 112.029 159.701 115.111Z"
        fill="#3164FA"
      />
      <path
        d="M264.693 69.3C263.665 69.3 262.719 69.795 262.13 70.5992C261.869 70.9556 261.41 71.164 261 71C260.59 70.8359 260.385 70.3653 260.615 69.9881C261.472 68.5832 263.009 67.7 264.693 67.7H272.5C277.913 67.7 282.3 72.0876 282.3 77.5V83.4717C282.948 83.7401 283.618 84.0975 284.329 84.5716C286.719 86.1664 288.303 88.2871 289.35 90.3833C290.396 92.4764 290.914 94.5599 291.173 96.1133C291.303 96.8919 291.368 97.5424 291.401 98.0015C291.417 98.2312 291.425 98.4133 291.43 98.54C291.432 98.6033 291.433 98.6529 291.433 98.6876L291.434 98.7242C291.65 103.17 292.174 107.596 293.004 111.97C293.086 112.404 292.801 112.822 292.367 112.905C291.933 112.987 291.514 112.702 291.432 112.268C290.587 107.816 290.054 103.311 289.835 98.785C289.834 98.7724 289.834 98.7591 289.834 98.7464L289.834 98.7412L289.834 98.7134C289.833 98.6875 289.832 98.6471 289.831 98.5932C289.827 98.4854 289.82 98.3235 289.805 98.1154C289.775 97.6988 289.715 97.0986 289.595 96.3761C289.354 94.9274 288.873 93.0081 287.919 91.0984C286.966 89.1919 285.55 87.3098 283.441 85.9025C281.336 84.4986 278.5 84.2999 277.8 84.2999C277.358 84.2999 277 83.9418 277 83.4999C277 83.0581 277.358 82.6999 277.8 82.6999C278.799 82.6999 279.743 82.7563 280.7 82.9675V77.5C280.7 72.9712 277.029 69.3 272.5 69.3H264.693Z"
        fill="#001F5C"
      />
      <path
        d="M258.851 104.553C258.421 104.656 258.156 105.087 258.258 105.517C259.642 111.334 262.157 114.834 264.373 116.888C265.478 117.911 266.5 118.567 267.257 118.971C267.635 119.172 267.946 119.311 268.169 119.4C268.28 119.445 268.369 119.478 268.433 119.5C268.465 119.511 268.49 119.52 268.509 119.526L268.533 119.533L268.541 119.536L268.545 119.537L268.605 119.553L268.546 119.538C268.97 119.664 269.415 119.424 269.542 119.001C269.668 118.578 269.43 118.134 269.008 118.006L269.007 118.005L269.005 118.005L269.001 118.004C268.994 118.001 268.979 117.996 268.959 117.989C268.919 117.975 268.854 117.951 268.767 117.917C268.594 117.847 268.334 117.732 268.01 117.559C267.361 117.213 266.454 116.634 265.461 115.714C263.609 113.998 261.428 111.069 260.08 106.181C259.987 105.845 259.899 105.501 259.815 105.146C259.712 104.717 259.281 104.451 258.851 104.553Z"
        fill="#001F5C"
      />
      <path
        d="M284.827 101.881L284.825 101.879C284.822 101.875 284.815 101.867 284.804 101.856C284.784 101.833 284.75 101.798 284.703 101.75C284.609 101.656 284.463 101.515 284.265 101.344C283.867 101.002 283.261 100.538 282.443 100.071C280.811 99.1401 278.329 98.1952 274.964 98.1952C271.593 98.1952 268.936 99.1248 267.125 100.051C266.218 100.514 265.525 100.976 265.063 101.318C264.831 101.489 264.658 101.63 264.546 101.725C264.489 101.773 264.448 101.81 264.422 101.833L264.404 101.849L264.395 101.858L264.391 101.861L264.39 101.863C264.07 102.166 263.564 102.153 263.26 101.834C262.955 101.514 262.967 101.007 263.287 100.703L263.29 100.7L263.294 100.696L263.307 100.684C263.318 100.674 263.333 100.661 263.351 100.644C263.389 100.61 263.442 100.563 263.511 100.505C263.649 100.388 263.85 100.225 264.111 100.032C264.634 99.6449 265.402 99.1348 266.396 98.6263C268.386 97.6091 271.292 96.5952 274.964 96.5952C278.643 96.5952 281.395 97.6315 283.236 98.6814C284.154 99.2054 284.844 99.7317 285.309 100.132C285.542 100.333 285.718 100.502 285.839 100.624C285.9 100.685 285.947 100.734 285.98 100.77C285.996 100.788 286.009 100.802 286.019 100.813L286.031 100.827L286.036 100.833C286.036 100.833 285.98 100.77 286.037 100.834C286.326 101.169 286.29 101.674 285.955 101.963C285.622 102.251 285.118 102.215 284.828 101.882L284.827 101.881Z"
        fill="#001F5C"
      />
      <path
        d="M284.825 94.228L284.827 94.2302L284.829 94.2315C285.118 94.564 285.622 94.6001 285.955 94.3117C286.29 94.0228 286.326 93.5176 286.037 93.1834L286.031 93.1756C286.037 93.1834 286.036 93.182 286.036 93.182L286.031 93.1756L286.019 93.1623C286.009 93.1515 285.996 93.1371 285.98 93.1192C285.947 93.0835 285.9 93.0341 285.839 92.973C285.718 92.8509 285.542 92.6817 285.309 92.4813C284.844 92.0808 284.154 91.5545 283.236 91.0305C281.395 89.9806 278.643 88.9443 274.964 88.9443C271.292 88.9443 268.386 89.9582 266.396 90.9755C265.402 91.4839 264.634 91.994 264.111 92.3807C263.85 92.5741 263.649 92.7371 263.511 92.854C263.442 92.9124 263.389 92.9594 263.351 92.9931C263.333 93.0099 263.318 93.0233 263.307 93.0332L263.294 93.0453L263.29 93.0493L263.287 93.0519C262.967 93.3565 262.955 93.8629 263.26 94.1829C263.564 94.5025 264.07 94.5152 264.39 94.2117L264.391 94.2106L264.395 94.2069C264.4 94.2021 264.409 94.1937 264.422 94.1821L264.453 94.1548C264.467 94.1422 264.483 94.1276 264.502 94.1113L264.546 94.0744C264.658 93.9788 264.831 93.838 265.063 93.6671C265.525 93.325 266.218 92.8633 267.125 92.4001C268.936 91.4739 271.593 90.5443 274.964 90.5443C278.329 90.5443 280.811 91.4892 282.443 92.4203C283.261 92.8869 283.867 93.3511 284.265 93.6935C284.463 93.8646 284.609 94.0049 284.703 94.0995C284.75 94.1467 284.784 94.1824 284.804 94.2048C284.815 94.216 284.822 94.2238 284.825 94.228Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.715 176.108L148.715 176.138L148.715 176.109C148.711 176.234 148.736 176.359 148.788 176.473C150.473 180.125 153.252 181.8 155.595 182.549C156.761 182.922 157.819 183.067 158.588 183.119C158.716 183.127 158.837 183.134 158.949 183.138V215.197C158.949 215.639 159.307 215.997 159.749 215.997H230.618C231.059 215.997 231.418 215.639 231.418 215.197V177.161C231.418 176.719 231.059 176.361 230.618 176.361H159.749C159.307 176.361 158.949 176.719 158.949 177.161V181.537C158.87 181.533 158.785 181.528 158.696 181.522C158.025 181.477 157.098 181.35 156.083 181.025C154.102 180.392 151.786 179.012 150.325 175.98L150.33 175.901C150.342 175.72 150.364 175.45 150.4 175.106C150.473 174.417 150.604 173.429 150.837 172.247C151.305 169.878 152.178 166.748 153.8 163.674C155.42 160.603 157.776 157.607 161.201 155.466C164.62 153.329 169.156 152.011 175.202 152.371C175.643 152.398 176.021 152.061 176.048 151.62C176.074 151.179 175.738 150.8 175.297 150.774C168.956 150.396 164.083 151.778 160.353 154.109C156.631 156.436 154.102 159.673 152.385 162.928C150.669 166.18 149.754 169.469 149.267 171.937C149.023 173.173 148.886 174.209 148.809 174.938C148.771 175.303 148.747 175.592 148.734 175.791C148.727 175.891 148.722 175.968 148.719 176.022L148.716 176.084L148.716 176.101L148.715 176.108ZM160.549 182.634C160.588 182.54 160.609 182.438 160.609 182.331V178.468C160.609 178.361 160.587 178.258 160.549 178.165V177.961H229.818V214.397H160.549V182.634Z"
        fill="#001F5C"
      />
      <path
        d="M165.185 157.181C164.807 157.411 164.688 157.903 164.917 158.28C165.46 159.173 166.806 161.933 168.04 164.526C168.651 165.81 169.227 167.036 169.65 167.941C169.861 168.394 170.034 168.766 170.155 169.025L170.342 169.429C170.528 169.83 171.003 170.005 171.404 169.82C171.805 169.634 171.98 169.158 171.794 168.757L171.606 168.351C171.485 168.091 171.311 167.718 171.099 167.264C170.675 166.356 170.098 165.127 169.484 163.838C168.27 161.285 166.881 158.43 166.284 157.449C166.054 157.071 165.562 156.952 165.185 157.181Z"
        fill="#001F5C"
      />
      <path
        d="M160.448 162.568C160.07 162.797 159.948 163.288 160.177 163.667C160.719 164.566 161.67 166.551 162.503 168.356C162.915 169.25 163.292 170.084 163.566 170.696C163.702 171.002 163.814 171.252 163.89 171.425L163.979 171.625L164.009 171.694C164.187 172.098 164.659 172.282 165.064 172.104C165.468 171.926 165.652 171.454 165.474 171.05L165.442 170.978L165.353 170.776C165.276 170.602 165.164 170.35 165.026 170.042C164.75 169.427 164.371 168.586 163.956 167.686C163.134 165.905 162.144 163.831 161.547 162.84C161.318 162.462 160.827 162.34 160.448 162.568Z"
        fill="#001F5C"
      />
      <path
        d="M156.033 168.975C155.655 169.204 155.534 169.696 155.763 170.074C156.327 171.007 157.165 172.465 157.866 173.697C158.216 174.312 158.53 174.867 158.757 175.269C158.87 175.47 158.962 175.633 159.025 175.745L159.123 175.919C159.339 176.305 159.826 176.442 160.212 176.226C160.597 176.01 160.734 175.521 160.518 175.136L160.42 174.961C160.356 174.848 160.264 174.685 160.151 174.483C159.923 174.08 159.608 173.522 159.257 172.906C158.556 171.675 157.708 170.199 157.132 169.246C156.903 168.868 156.411 168.747 156.033 168.975Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.789 168.444C214.789 168.002 214.431 167.644 213.989 167.644H180.337C179.895 167.644 179.537 168.002 179.537 168.444V171.217C179.537 171.659 179.895 172.017 180.337 172.017H213.989C214.431 172.017 214.789 171.659 214.789 171.217V168.444ZM213.189 169.244V170.417H181.137V169.244H213.189Z"
        fill="#001F5C"
      />
    </svg>
  );
};
