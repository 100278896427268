import React from 'react';
import {type IconType} from '..';

export const IconItalic: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M14.8 0H6C5.68174 0 5.37652 0.126428 5.15147 0.351472C4.92643 0.576515 4.8 0.88174 4.8 1.2C4.8 1.51826 4.92643 1.82348 5.15147 2.04853C5.37652 2.27357 5.68174 2.4 6 2.4H8.704L4.752 13.6H1.2C0.88174 13.6 0.576515 13.7264 0.351472 13.9515C0.126428 14.1765 0 14.4817 0 14.8C0 15.1183 0.126428 15.4235 0.351472 15.6485C0.576515 15.8736 0.88174 16 1.2 16H10C10.3183 16 10.6235 15.8736 10.8485 15.6485C11.0736 15.4235 11.2 15.1183 11.2 14.8C11.2 14.4817 11.0736 14.1765 10.8485 13.9515C10.6235 13.7264 10.3183 13.6 10 13.6H7.296L11.248 2.4H14.8C15.1183 2.4 15.4235 2.27357 15.6485 2.04853C15.8736 1.82348 16 1.51826 16 1.2C16 0.88174 15.8736 0.576515 15.6485 0.351472C15.4235 0.126428 15.1183 0 14.8 0V0Z"
        fill="currentColor"
      />
    </svg>
  );
};
