import React from 'react';
import {type IconType} from '..';

export const IconListUnordered: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33331 1H0.99999C0.446662 1 0 1.44666 0 1.99999V3.33331C0 3.87997 0.446662 4.3333 0.99999 4.3333H2.33331C2.87997 4.3333 3.3333 3.87997 3.3333 3.33331V1.99999C3.3333 1.44666 2.87997 1 2.33331 1ZM15.3332 3.33332H5.66661C5.29328 3.33332 4.99995 3.03332 4.99995 2.66666C4.99995 2.29333 5.29328 2 5.66661 2H15.3332C15.6998 2 15.9998 2.29333 15.9998 2.66666C15.9998 3.03332 15.6998 3.33332 15.3332 3.33332ZM5.66661 7.33325H15.3332C15.6998 7.33325 15.9998 7.62658 15.9998 7.99991C15.9998 8.36658 15.6998 8.66657 15.3332 8.66657H5.66661C5.29328 8.66657 4.99995 8.36658 4.99995 7.99991C4.99995 7.62658 5.29328 7.33325 5.66661 7.33325ZM15.3332 12.6665H5.66661C5.29328 12.6665 4.99995 12.9598 4.99995 13.3332C4.99995 13.6998 5.29328 13.9998 5.66661 13.9998H15.3332C15.6998 13.9998 15.9998 13.6998 15.9998 13.3332C15.9998 12.9598 15.6998 12.6665 15.3332 12.6665ZM2.33331 6.33325H0.99999C0.446662 6.33325 0 6.77991 0 7.33324V8.66656C0 9.21322 0.446662 9.66655 0.99999 9.66655H2.33331C2.87997 9.66655 3.3333 9.21322 3.3333 8.66656V7.33324C3.3333 6.77991 2.87997 6.33325 2.33331 6.33325ZM2.33331 11.6665H0.99999C0.446662 11.6665 0 12.1132 0 12.6665V13.9998C0 14.5465 0.446662 14.9998 0.99999 14.9998H2.33331C2.87997 14.9998 3.3333 14.5465 3.3333 13.9998V12.6665C3.3333 12.1132 2.87997 11.6665 2.33331 11.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};
