import React from 'react';
import {type IconType} from '../../../icons';

export const Long: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M187 111H157C157 111 157.138 101.652 167.163 93.0005C177.189 84.3489 166.887 63.8404 182.344 47.6576C197.801 31.4748 216.741 30.493 221.616 46.5415C226.49 62.5901 220.36 80.0232 230.247 90.2103C240.134 100.397 240 111 240 111H212V97.8843C212 97.8843 221.867 85.2216 218.89 63.8572C206.327 60.6307 203.106 51.2701 203.106 51.2701C203.106 51.2701 199.337 61.3397 179.433 65.0236C178.969 78.2694 180.233 87.0929 187 97.922V111Z"
        fill="#001F5C"
      />
    </svg>
  );
};
