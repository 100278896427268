import React from 'react';
import {type IconType} from '../../../icons';

export const SmallWeirdOne: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.25 71.7838C183.25 69.0437 187.017 68.2904 188.071 70.8197C188.415 71.6457 189.585 71.6457 189.929 70.8197C190.983 68.2904 194.75 69.0437 194.75 71.7838V75.0908L203.25 75.0908V71.7838C203.25 69.0437 207.017 68.2904 208.071 70.8197C208.415 71.6457 209.585 71.6457 209.929 70.8197C210.983 68.2904 214.75 69.0437 214.75 71.7838V74.715L220.588 70.8735C220.934 70.6458 221.399 70.7417 221.627 71.0877C221.854 71.4338 221.758 71.8988 221.412 72.1265L214.816 76.4675C214.794 76.4816 214.772 76.4944 214.75 76.5061V78.8334C214.75 80.9965 212.996 82.75 210.833 82.75H207.167C205.004 82.75 203.25 80.9965 203.25 78.8334V76.5908L194.75 76.5908V78.8334C194.75 80.9965 192.996 82.75 190.833 82.75H187.167C185.004 82.75 183.25 80.9965 183.25 78.8334V76.5061C183.184 76.4717 183.122 76.4267 183.066 76.3712L177.47 70.7745C177.177 70.4816 177.177 70.0067 177.47 69.7138C177.763 69.4209 178.237 69.4209 178.53 69.7138L183.25 74.4335V71.7838ZM186.686 71.3966C186.263 70.3808 184.75 70.6833 184.75 71.7838V78.8334C184.75 80.168 185.832 81.25 187.167 81.25H190.833C192.168 81.25 193.25 80.168 193.25 78.8334V71.7838C193.25 70.6833 191.737 70.3808 191.314 71.3966C190.457 73.4534 187.543 73.4534 186.686 71.3966ZM204.75 71.7838C204.75 70.6833 206.263 70.3808 206.686 71.3966C207.543 73.4534 210.457 73.4534 211.314 71.3966C211.737 70.3808 213.25 70.6833 213.25 71.7838V78.8334C213.25 80.168 212.168 81.25 210.833 81.25H207.167C205.832 81.25 204.75 80.168 204.75 78.8334V71.7838Z"
        fill="#001F5C"
      />
    </svg>
  );
};
