import React from 'react';
import {type IconType} from '../../../icons';

export const Elevating: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M292.635 70.5092C302.157 70.5092 309.876 62.7841 309.876 53.2546C309.876 43.7251 302.157 36 292.635 36C283.113 36 275.393 43.7251 275.393 53.2546C275.393 62.7841 283.113 70.5092 292.635 70.5092Z"
        fill="#3164FA"
      />
      <path
        d="M226.277 108.431H212V118.086C212 118.086 211.848 120.194 200.128 120.194C188.408 120.194 188 117.492 188 117.492V108.431H178.13C157.379 108.431 144 128.274 144 149.045V191.103C144 202.148 152.954 211.103 164 211.103H223.135C227.741 211.103 231.751 207.957 232.848 203.483L240.554 172.036C241.334 169.078 242.156 165.789 242.88 162.544L245.745 150.856L259.756 156.184L269.256 121.583C269.179 121.544 269.063 121.484 268.908 121.404C267.686 120.769 264.078 118.896 258.567 116.751C252.36 114.336 243.739 118.157 242.015 123.653C240.128 129.669 230.248 159.804 225.614 173.927C224.922 175.502 224.291 177.15 223.739 178.868C217.532 198.193 197.187 193.707 192.705 189.566C188.222 185.425 187.187 183.354 187.187 183.354L173.67 158.54L199.329 144.045L204.988 157.742C205.156 158.15 205.624 158.344 206.033 158.176C206.441 158.007 206.635 157.539 206.466 157.131L206.055 156.136L209.309 150.571C209.309 150.571 208.796 150.286 214.207 141.182C218.717 133.621 226.277 108.431 226.277 108.431Z"
        fill="#3164FA"
      />
      <path
        d="M274.023 42.8713C274.023 46.6663 270.949 49.7427 267.157 49.7427C263.365 49.7427 260.291 46.6663 260.291 42.8713C260.291 39.0764 263.365 36 267.157 36C270.949 36 274.023 39.0764 274.023 42.8713Z"
        fill="#3164FA"
      />
      <path
        d="M321.824 68.8602C321.942 68.4344 321.693 67.9935 321.267 67.8755C320.841 67.7574 320.4 68.0069 320.282 68.4326C320.252 68.5425 320.22 68.6517 320.186 68.76C318.859 73.0603 315.233 76.1312 311.774 78.1634C310.015 79.1971 308.333 79.9416 307.09 80.4276C306.469 80.6702 305.96 80.8476 305.608 80.9638C305.433 81.0218 305.296 81.0645 305.205 81.0923C305.159 81.1063 305.125 81.1165 305.103 81.123L305.078 81.1301L305.073 81.1316C304.648 81.252 304.4 81.6943 304.521 82.1193C304.641 82.5444 305.083 82.7916 305.508 82.6713L305.291 81.9015C305.508 82.6713 305.508 82.6713 305.508 82.6713L305.51 82.6709L305.513 82.6701L305.522 82.6675L305.554 82.6582C305.581 82.6501 305.621 82.6383 305.672 82.6227C305.774 82.5915 305.922 82.5452 306.11 82.4831C306.486 82.3591 307.022 82.172 307.673 81.9177C308.972 81.4096 310.734 80.63 312.584 79.543C316.258 77.385 320.417 73.9325 321.824 68.8602Z"
        fill="#001F5C"
      />
      <path
        d="M325.283 72.0817C325.713 72.1856 325.977 72.618 325.873 73.0474C324.946 76.8779 322.357 79.8576 319.105 82.1618C315.854 84.4652 311.883 86.1363 308.066 87.3425C304.242 88.5506 300.534 89.3038 297.786 89.7552C296.41 89.9811 295.273 90.1319 294.477 90.2263C294.366 90.2395 294.261 90.2516 294.163 90.2626L307.593 141.344C311.74 157.107 296.341 170.967 281.117 165.171L244.649 151.292C244.236 151.135 244.029 150.673 244.186 150.26C244.343 149.847 244.805 149.639 245.218 149.797L281.686 163.676C295.69 169.008 309.862 156.258 306.046 141.751L292.378 89.7658C292.318 89.5372 292.362 89.2938 292.499 89.1007C292.635 88.9077 292.85 88.7849 293.085 88.7651L293.089 88.7648L293.103 88.7636L293.161 88.7584C293.213 88.7536 293.291 88.7463 293.393 88.736C293.598 88.7155 293.901 88.6834 294.288 88.6374C295.064 88.5454 296.178 88.3979 297.526 88.1764C300.226 87.733 303.855 86.9949 307.584 85.8168C311.318 84.6369 315.117 83.027 318.18 80.8563C321.242 78.6865 323.512 75.9992 324.318 72.6711C324.422 72.2417 324.854 71.9778 325.283 72.0817Z"
        fill="#001F5C"
      />
      <path
        d="M275.579 75.4634C274.404 75.3936 273.228 75.6036 272.139 76.0791C271.05 76.5547 270.073 77.2844 269.285 78.2164C268.497 79.1485 267.918 80.2583 267.594 81.4637C267.271 82.6692 267.211 83.9366 267.42 85.1703L276.633 140.085C276.706 140.521 276.412 140.934 275.976 141.007C275.541 141.08 275.128 140.786 275.055 140.35L265.842 85.4378C265.595 83.9775 265.665 82.4767 266.049 81.0485C266.433 79.62 267.121 78.2983 268.064 77.1833C269.006 76.0682 270.18 75.1884 271.499 74.6128C272.81 74.0403 274.229 73.7842 275.65 73.8648H296.153C296.595 73.8648 296.953 74.223 296.953 74.6648C296.953 75.1066 296.595 75.4648 296.153 75.4648H275.627C275.611 75.4648 275.595 75.4643 275.579 75.4634Z"
        fill="#001F5C"
      />
      <path
        d="M226.373 110.565C229.101 99.634 236.138 95.1837 240.749 95.6557C241.188 95.7006 241.581 95.3808 241.626 94.9413C241.671 94.5017 241.351 94.109 240.912 94.064C235.186 93.4779 227.651 98.8386 224.82 110.178C222.849 118.072 219.948 126.413 218.429 130.592C217.816 132.279 217.09 133.924 216.259 135.515L197.199 172.049C196.995 172.441 197.147 172.924 197.539 173.128C197.93 173.333 198.413 173.181 198.618 172.789L217.677 136.255C218.542 134.601 219.296 132.892 219.933 131.138C221.457 126.945 224.381 118.541 226.373 110.565Z"
        fill="#001F5C"
      />
      <path
        d="M242.775 123.902C242.913 123.482 242.684 123.03 242.264 122.893C241.844 122.755 241.392 122.984 241.255 123.404L223.015 179.081L223.012 179.091C221.785 182.997 219.345 186.409 216.047 188.832C212.749 191.255 208.765 192.561 204.674 192.562C201.21 192.562 197.811 191.625 194.836 189.851C191.86 188.076 189.419 185.53 187.771 182.481L174.374 157.718C174.163 157.329 173.678 157.185 173.289 157.395C172.901 157.605 172.756 158.091 172.966 158.479L186.363 183.243C188.149 186.545 190.793 189.303 194.016 191.225C197.239 193.147 200.922 194.162 204.674 194.162C209.106 194.161 213.422 192.746 216.994 190.121C220.565 187.498 223.208 183.803 224.537 179.575L224.538 179.57L242.775 123.902Z"
        fill="#001F5C"
      />
      <path
        d="M249.648 105.52L249.624 105.511C249.601 105.502 249.562 105.489 249.509 105.472C249.403 105.437 249.239 105.389 249.025 105.339C248.595 105.238 247.962 105.128 247.174 105.105C245.603 105.057 243.409 105.35 240.963 106.74C238.51 108.133 236.962 109.952 236.027 111.421C235.559 112.155 235.246 112.8 235.051 113.257C234.954 113.484 234.886 113.665 234.844 113.785C234.823 113.845 234.809 113.889 234.8 113.917L234.79 113.947L234.789 113.95L234.789 113.951C234.664 114.38 234.224 114.63 233.806 114.509C233.387 114.388 233.148 113.941 233.272 113.511L233.984 113.717C233.272 113.511 233.272 113.511 233.272 113.511L233.273 113.51L233.273 113.508L233.275 113.503L233.28 113.487C233.283 113.474 233.289 113.457 233.296 113.436C233.309 113.393 233.329 113.332 233.356 113.257C233.409 113.105 233.489 112.891 233.602 112.627C233.827 112.1 234.181 111.373 234.704 110.552C235.751 108.908 237.48 106.879 240.209 105.329C242.943 103.775 245.429 103.434 247.243 103.488C248.148 103.515 248.883 103.641 249.399 103.762C249.657 103.823 249.861 103.882 250.003 103.928C250.075 103.951 250.131 103.971 250.171 103.985C250.191 103.993 250.207 103.999 250.22 104.003L250.235 104.009L250.24 104.011L250.242 104.012C250.242 104.012 250.243 104.013 249.946 104.766L250.243 104.013C250.648 104.175 250.843 104.644 250.679 105.06C250.515 105.476 250.053 105.681 249.648 105.52Z"
        fill="#001F5C"
      />
      <path
        d="M246.982 100.544C247.392 100.69 247.845 100.467 247.993 100.044C248.142 99.6217 247.93 99.1602 247.519 99.0135L247.257 99.7594C247.519 99.0135 247.519 99.0135 247.519 99.0135L247.518 99.0129L247.516 99.0122L247.51 99.0103L247.495 99.0051C247.483 99.0009 247.467 98.9955 247.446 98.989C247.406 98.9759 247.349 98.9585 247.277 98.9383C247.133 98.8979 246.927 98.8461 246.667 98.7954C246.148 98.6942 245.409 98.597 244.505 98.6048C242.692 98.6203 240.222 99.0581 237.548 100.717C234.88 102.373 233.226 104.469 232.24 106.153C231.747 106.995 231.419 107.736 231.214 108.272C231.111 108.54 231.038 108.757 230.991 108.911C230.967 108.987 230.949 109.048 230.937 109.092C230.931 109.114 230.926 109.131 230.923 109.144L230.919 109.16L230.917 109.165L230.917 109.167C230.917 109.167 230.916 109.169 231.63 109.347L230.916 109.169C230.808 109.603 231.062 110.041 231.485 110.147C231.906 110.252 232.336 109.986 232.446 109.553L232.446 109.552L232.447 109.548L232.455 109.518C232.463 109.49 232.476 109.444 232.495 109.384C232.533 109.262 232.594 109.079 232.683 108.847C232.861 108.384 233.15 107.726 233.591 106.973C234.472 105.468 235.953 103.589 238.352 102.1C240.746 100.616 242.926 100.238 244.497 100.225C245.284 100.218 245.92 100.303 246.352 100.387C246.569 100.429 246.734 100.471 246.841 100.501C246.894 100.516 246.933 100.529 246.957 100.536L246.981 100.544L246.982 100.544Z"
        fill="#001F5C"
      />
    </svg>
  );
};
