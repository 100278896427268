import React from 'react';
import {type IconType} from '../../icons';

export const Gas: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M93.5716 36.4667C94.0134 36.4667 94.3716 36.1085 94.3716 35.6667C94.3716 35.2249 94.0134 34.8667 93.5716 34.8667H66.4287C65.9869 34.8667 65.6287 35.2249 65.6287 35.6667C65.6287 36.1085 65.9869 36.4667 66.4287 36.4667H79.2001V47.5413C62.6526 47.876 48.6498 58.5696 43.4081 73.3999C43.2608 73.8165 43.4792 74.2736 43.8957 74.4208C44.3123 74.568 44.7694 74.3497 44.9166 73.9331C50.0238 59.4837 63.8042 49.1332 80.0001 49.1332C100.545 49.1332 117.2 65.7882 117.2 86.3332C117.2 106.878 100.545 123.533 80.0001 123.533C60.1204 123.533 43.8829 107.939 42.852 88.3165L46.5255 91.9901C46.838 92.3025 47.3445 92.3025 47.6569 91.9901C47.9693 91.6776 47.9693 91.1711 47.6569 90.8587L42.5657 85.7675C42.2533 85.4551 41.7468 85.4551 41.4344 85.7675L36.3432 90.8587C36.0308 91.1711 36.0308 91.6776 36.3432 91.9901C36.6556 92.3025 37.1622 92.3025 37.4746 91.9901L41.2451 88.2195C42.2289 108.772 59.2038 125.133 80.0001 125.133C101.429 125.133 118.8 107.762 118.8 86.3332C118.8 65.1719 101.859 47.9672 80.8001 47.5413V36.4667H93.5716Z"
        fill="#001F5C"
      />
      <path
        d="M60.7353 46.0436C61.0004 46.3971 60.9287 46.8985 60.5753 47.1636L46.0991 58.0208C45.7456 58.2859 45.2442 58.2143 44.9791 57.8608C44.714 57.5074 44.7856 57.0059 45.1391 56.7408L59.6153 45.8836C59.9687 45.6185 60.4702 45.6902 60.7353 46.0436Z"
        fill="#001F5C"
      />
      <path
        d="M99.4248 47.1636C99.0714 46.8985 98.9997 46.3971 99.2648 46.0436C99.5299 45.6902 100.031 45.6185 100.385 45.8836L114.861 56.7408C115.214 57.0059 115.286 57.5074 115.021 57.8608C114.756 58.2143 114.254 58.2859 113.901 58.0208L99.4248 47.1636Z"
        fill="#001F5C"
      />
      <path
        d="M80.6975 95.8544C80.6975 95.8545 80.6975 95.8545 80.6974 95.8545C77.6731 95.8545 75.6582 94.6926 74.674 92.3752C74.4725 91.9009 73.9853 91.5903 73.4787 91.6847L65.6845 93.1381C65.1104 93.2452 64.7475 93.8212 64.9311 94.3754C66.7307 99.8098 70.5564 103.005 76.4203 103.946C76.9206 104.027 77.3016 104.451 77.3016 104.957V107.952C77.3016 108.505 77.7493 108.952 78.3016 108.952H81.6629C82.2152 108.952 82.6629 108.505 82.6629 107.952V104.963C82.6629 104.454 83.0466 104.029 83.5503 103.953C87.0934 103.421 89.8943 102.145 91.9902 100.145C94.2473 97.9761 95.381 95.2846 95.381 92.0822C95.381 89.8061 94.7892 87.8021 93.6325 86.1295C92.4623 84.4569 91.0062 83.2585 89.2691 82.5652C87.504 81.8526 84.6275 81.0696 80.5835 80.1658C78.8215 79.7646 77.7544 79.3827 77.3538 78.9925C76.97 78.6139 76.7704 78.1365 76.7704 77.5951C76.7704 76.0879 77.9348 75.3468 80.2309 75.3468C82.6234 75.3468 84.3135 76.2501 85.3006 78.0623C85.5549 78.5292 86.0895 78.8002 86.5966 78.6404L93.4294 76.4878C93.9711 76.3172 94.2653 75.7282 94.0377 75.2078C92.1744 70.9485 88.6759 68.4249 83.5537 67.6368C83.0501 67.5593 82.6654 67.134 82.6654 66.6245V63.8096C82.6654 63.2573 82.2177 62.8096 81.6654 62.8096H78.3041C77.7518 62.8096 77.3041 63.2573 77.3041 63.8096V66.768C77.3041 67.2627 76.9412 67.6803 76.4555 67.7739C73.1758 68.4063 70.7043 69.7304 69.0414 71.7232C67.2404 73.8984 66.3396 76.365 66.3396 79.1721C66.3396 81.7149 67.1707 83.9515 68.8162 85.9019C70.4502 87.8581 74.3529 89.4551 80.4838 90.7208C82.0538 91.0381 83.1325 91.4115 83.7441 91.8488C84.35 92.2642 84.6558 92.8844 84.6558 93.6481C84.6552 95.1133 83.3462 95.8544 80.6975 95.8544C80.6975 95.8544 80.6975 95.8544 80.6975 95.8544Z"
        fill="#3164FA"
      />
    </svg>
  );
};
