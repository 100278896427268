import React from 'react';
import {type IconType} from '../../icons';

export const Archive: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        d="M56.2053 50.2766C56.356 50.0711 56.5956 49.9497 56.8505 49.9497L33.2891 81.7716C33.2926 81.5996 33.3504 81.4409 33.446 81.312L56.2053 50.2766Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.5062 96.2485C61.0643 96.2485 60.7062 96.6067 60.7062 97.0485V105.843C60.7062 106.285 61.0643 106.643 61.5062 106.643H99.2697C99.7115 106.643 100.07 106.285 100.07 105.843V97.0485C100.07 96.6067 99.7115 96.2485 99.2697 96.2485H61.5062ZM62.3062 105.043V97.8485H98.4697V105.043H62.3062Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8505 49.9497L33.2889 81.7908V118C33.2889 118.442 33.6471 118.8 34.0889 118.8H79.8707C79.9148 118.8 79.958 118.796 80.0002 118.789C80.0422 118.796 80.0854 118.8 80.1293 118.8H125.911C126.353 118.8 126.711 118.442 126.711 118V81.792L126.711 81.7881C126.711 81.7663 126.71 81.7445 126.708 81.7228C126.696 81.5684 126.64 81.4264 126.552 81.3092L103.795 50.2766C103.644 50.0711 103.404 49.9497 103.15 49.9497H100.304C99.8625 49.9497 99.5044 50.3079 99.5044 50.7497C99.5044 51.1915 99.8625 51.5497 100.304 51.5497H102.744L124.332 80.9882H100.304C100.061 80.9882 99.8315 81.0987 99.6797 81.2885L94.7468 87.4546H78.5799L78.5774 87.4546H65.2532L60.3203 81.2885C60.1685 81.0987 59.9387 80.9882 59.6956 80.9882H35.6676L57.2558 51.5497H59.6956C60.1375 51.5497 60.4956 51.1915 60.4956 50.7497C60.4956 50.3079 60.1375 49.9497 59.6956 49.9497H56.8505ZM100.689 82.5882H125.111V117.2H80.1293C80.0852 117.2 80.042 117.203 79.9998 117.21C79.9578 117.203 79.9146 117.2 79.8707 117.2H34.8889V82.5882H59.3111L64.244 88.7543C64.3959 88.9441 64.6257 89.0546 64.8687 89.0546H95.1313C95.3743 89.0546 95.6042 88.9441 95.756 88.7543L100.689 82.5882Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4697 72.1938C45.0279 72.1938 44.6697 72.552 44.6697 72.9938V78.3888C44.6697 78.8306 45.0279 79.1888 45.4697 79.1888C45.9115 79.1888 46.2697 78.8306 46.2697 78.3888V73.7938H112.954V78.3888C112.954 78.8306 113.313 79.1888 113.754 79.1888C114.196 79.1888 114.554 78.8306 114.554 78.3888V72.9938C114.554 72.552 114.196 72.1938 113.754 72.1938H108.492V64.696C108.492 64.2542 108.134 63.896 107.692 63.896H52.3076C51.8658 63.896 51.5076 64.2542 51.5076 64.696V72.1938H45.4697ZM106.892 65.496V72.1938H53.1076V65.496H106.892Z"
        fill="#001F5C"
      />
      <path
        d="M62.2798 45V62.1365H97.72V45C97.72 43.3431 96.3768 42 94.72 42H65.2798C63.6229 42 62.2798 43.3431 62.2798 45Z"
        fill="#3164FA"
      />
    </svg>
  );
};
