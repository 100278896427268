import React from 'react';
import {type IconType} from '../../../icons';

export const Correct: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M265.064 76.1883C264.645 76.3302 264.191 76.1062 264.05 75.6881L260.214 64.3451C260.073 63.927 260.298 63.473 260.717 63.3312C261.135 63.1893 261.59 63.4133 261.731 63.8314L265.567 75.1744C265.708 75.5925 265.483 76.0465 265.064 76.1883Z"
        fill="#3164FA"
      />
      <path
        d="M258.315 79.8409C257.972 80.12 257.467 80.0688 257.188 79.7267L244.851 64.5935C244.573 64.2514 244.625 63.7478 244.968 63.4687C245.311 63.1897 245.815 63.2408 246.094 63.5829L258.431 78.7161C258.71 79.0582 258.658 79.5618 258.315 79.8409Z"
        fill="#3164FA"
      />
      <path
        d="M143.58 127.592C145.757 122.38 149.425 117.928 154.124 114.798C158.823 111.668 164.342 109.998 169.986 110H187.456V118.017L187.456 118.042C187.456 118.093 187.455 118.144 187.469 118.193C187.587 118.604 188.729 120.336 199.648 120.336C209.838 120.336 212.175 118.438 212.684 117.765C212.793 117.62 212.818 117.439 212.818 117.258V110H225.34C230.576 110.001 235.7 111.511 240.1 114.351L269.337 133.219L267.515 127.297C267.063 125.828 268.373 124.366 269.897 124.555C275.046 125.191 284.771 125.556 289.945 120.36C291.042 119.258 293.055 119.167 293.882 120.484C297.291 125.916 304.643 137.75 310.113 147.406C317.468 160.39 323.288 176.984 309.308 185.351C295.329 193.719 281.841 191.441 266.241 180.063C250.641 168.685 238.616 158.249 238.616 158.249V198.89C238.616 201.099 236.825 202.891 234.616 202.891H148.654C137.019 202.891 132 195.932 132 186.563C132.024 180.292 134.998 148.187 143.58 127.592Z"
        fill="#3164FA"
      />
      <path
        d="M245.897 79.41C245.522 79.1758 245.028 79.2897 244.794 79.6644C244.56 80.039 244.674 80.5326 245.049 80.7668L253.049 85.7668C253.423 86.0009 253.917 85.887 254.151 85.5124C254.385 85.1377 254.271 84.6441 253.897 84.41L245.897 79.41Z"
        fill="#3164FA"
      />
      <path
        d="M292.103 75.7933C292.541 75.7362 292.85 75.3347 292.793 74.8966C292.736 74.4585 292.335 74.1496 291.896 74.2068C287.004 74.845 283.209 77.9824 280.479 81.5241C277.746 85.0699 276.005 89.1138 275.232 91.777C275.108 92.2013 275.353 92.6451 275.777 92.7683C276.201 92.8915 276.645 92.6474 276.768 92.2231C277.495 89.7196 279.154 85.8635 281.746 82.5009C284.341 79.1344 287.796 76.3551 292.103 75.7933Z"
        fill="#001F5C"
      />
      <path
        d="M298.74 81.1971C298.908 81.6061 298.712 82.0732 298.303 82.2405C289.733 85.7463 285.344 92.4656 284.245 95.29C284.085 95.7018 283.622 95.9058 283.21 95.7456C282.798 95.5855 282.594 95.1219 282.754 94.7101C283.989 91.5345 288.667 84.4537 297.697 80.7596C298.106 80.5923 298.573 80.7882 298.74 81.1971Z"
        fill="#001F5C"
      />
      <path
        d="M304.211 87.6337C304.413 88.0264 304.259 88.5089 303.866 88.7112C295.791 92.871 292.564 97.7121 291.071 102.191C290.317 104.455 289.996 106.654 289.739 108.701C289.711 108.928 289.683 109.154 289.655 109.379C289.438 111.146 289.231 112.838 288.759 114.253C287.869 116.921 285.878 118.569 283.779 119.472C281.697 120.368 279.439 120.566 277.861 120.288C277.426 120.211 277.135 119.796 277.212 119.361C277.289 118.926 277.704 118.635 278.139 118.712C279.394 118.934 281.336 118.782 283.146 118.003C284.939 117.231 286.53 115.879 287.241 113.747C287.658 112.495 287.846 110.977 288.068 109.175L288.068 109.171L288.069 109.166L288.069 109.163C288.096 108.947 288.123 108.726 288.151 108.502C288.41 106.44 288.746 104.108 289.553 101.685C291.186 96.788 294.708 91.6291 303.134 87.2889C303.526 87.0865 304.009 87.2409 304.211 87.6337Z"
        fill="#001F5C"
      />
      <path
        d="M269.313 80.6025C271.467 80.773 273.346 81.6026 274.444 82.3344C274.811 82.5794 274.911 83.0761 274.666 83.4438C274.42 83.8114 273.924 83.9107 273.556 83.6656C272.654 83.0641 271.033 82.3436 269.187 82.1975C267.364 82.0532 265.343 82.4672 263.535 84.0946C261.701 85.7451 260.763 87.9421 260.348 90.066C259.987 91.9119 260.031 93.6568 260.199 94.8404C261.108 94.9975 262.22 94.9094 263.451 94.2863C264.92 93.5426 266.611 92.008 268.309 89.0969C268.531 88.7153 269.021 88.5864 269.403 88.809C269.785 89.0316 269.914 89.5215 269.691 89.9031C267.889 92.992 265.997 94.7908 264.174 95.7138C263.024 96.2957 261.925 96.5179 260.932 96.5079C262.007 97.1436 262.968 98.0121 263.665 99.0557C263.911 99.4232 263.812 99.9199 263.444 100.165C263.077 100.411 262.58 100.312 262.335 99.9443C261.448 98.6173 259.987 97.6085 258.467 97.1658C257.078 96.7614 255.76 96.857 254.804 97.4619C254.736 99.9043 255.445 104.187 257.649 108.293C259.955 112.59 263.868 116.643 270.194 118.224C270.623 118.331 270.883 118.765 270.776 119.194C270.669 119.623 270.235 119.883 269.806 119.776C262.951 118.063 258.709 113.652 256.239 109.05C253.783 104.473 253.048 99.6577 253.225 96.986C253.24 96.7629 253.347 96.5562 253.521 96.4159C254.971 95.2468 256.916 95.1037 258.699 95.57C258.441 94.2172 258.328 92.0555 258.777 89.759C259.237 87.4079 260.299 84.8549 262.465 82.9054C264.657 80.9328 267.136 80.4301 269.313 80.6025Z"
        fill="#001F5C"
      />
    </svg>
  );
};
