import React from 'react';
import {type IconType} from '../../../icons';

export const Cool: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M199.04 63.0978C199.04 63.0978 191.5 50.5 184.5 55.5C178.724 60.4182 178.963 76 178.963 76C177 74.8595 176.571 73.5 176 69.5C175.5 66 174.316 61.5 173 56.5C170.5 47 165.617 27.5 184.5 27.5C217.826 27.5 225.534 51.8293 225.534 63.0978C225.534 70.3658 225.534 72.008 225.534 72.008C225.534 72.008 222.788 71.4377 219.826 74.8595C218.631 63.1836 211.5 59.5 199.04 63.0978Z"
        fill="#001F5C"
      />
    </svg>
  );
};
