import React from 'react';
import {type IconType} from '../../icons';

export const Build: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.9417 40.2283C69.5148 39.6131 67.0277 41.0922 66.4123 43.52L62.7267 58.0603C62.1113 60.488 63.5934 62.9733 66.0203 63.5885L80.5598 67.2739C82.9854 67.8888 85.4723 66.4094 86.0876 63.9818L89.7733 49.4415C90.3886 47.014 88.9068 44.5286 86.4812 43.9137L71.9417 40.2283ZM67.9633 43.9131C68.3614 42.3427 69.9773 41.3809 71.5486 41.7792L86.0881 45.4647C87.6575 45.8625 88.6205 47.4778 88.2224 49.0484L84.5367 63.5887C84.1386 65.1593 82.5224 66.1208 80.953 65.723L66.4135 62.0375C64.8422 61.6392 63.8796 60.0238 64.2776 58.4534L67.9633 43.9131Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2002 46.2509C34.2002 43.7463 36.2475 41.7015 38.7512 41.7015H53.7506C56.2529 41.7015 58.3 43.7466 58.3 46.2509V61.2511C58.3 63.7555 56.2529 65.8005 53.7506 65.8005H38.7512C36.2475 65.8005 34.2002 63.7556 34.2002 61.2511V46.2509ZM38.7512 43.3015C37.1303 43.3015 35.8002 44.6309 35.8002 46.2509V61.2511C35.8002 62.8711 37.1303 64.2005 38.7512 64.2005H53.7506C55.3697 64.2005 56.7 62.8714 56.7 61.2511V46.2509C56.7 44.6307 55.3697 43.3015 53.7506 43.3015H38.7512Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.359 70.1021C99.9516 69.4128 97.4204 70.8148 96.7308 73.2227L92.601 87.6423C91.9114 90.05 93.3165 92.5795 95.7235 93.2689L110.143 97.3987C112.549 98.0877 115.08 96.6853 115.769 94.2777L119.899 79.8581C120.589 77.4506 119.184 74.9209 116.778 74.2319L102.359 70.1021ZM98.2689 73.6632C98.715 72.1058 100.36 71.194 101.918 71.6403L116.338 75.7701C117.894 76.2159 118.807 77.86 118.361 79.4176L114.231 93.8372C113.785 95.3948 112.14 96.3063 110.584 95.8605L96.164 91.7307C94.6057 91.2844 93.6931 89.6402 94.1391 88.0828L98.2689 73.6632Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7512 71.701C36.2475 71.701 34.2002 73.7458 34.2002 76.2505V91.2498C34.2002 93.7543 36.2475 95.7992 38.7512 95.7992H53.7506C56.2529 95.7992 58.3 93.7542 58.3 91.2498V76.2505C58.3 73.7461 56.2529 71.701 53.7506 71.701H38.7512ZM35.8002 76.2505C35.8002 74.6304 37.1303 73.301 38.7512 73.301H53.7506C55.3697 73.301 56.7 74.6302 56.7 76.2505V91.2498C56.7 92.8701 55.3697 94.1992 53.7506 94.1992H38.7512C37.1303 94.1992 35.8002 92.8698 35.8002 91.2498V76.2505Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.7512 101.701C36.2475 101.701 34.2002 103.745 34.2002 106.25V121.249C34.2002 123.753 36.247 125.8 38.7512 125.8H53.7506C56.2533 125.8 58.3 123.753 58.3 121.249V106.25C58.3 103.746 56.2529 101.701 53.7506 101.701H38.7512ZM35.8002 106.25C35.8002 104.63 37.1303 103.301 38.7512 103.301H53.7506C55.3697 103.301 56.7 104.63 56.7 106.25V121.249C56.7 122.87 55.3692 124.2 53.7506 124.2H38.7512C37.1307 124.2 35.8002 122.87 35.8002 121.249V106.25Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7503 101.701C66.2466 101.701 64.1992 103.745 64.1992 106.25V121.249C64.1992 123.753 66.2461 125.8 68.7503 125.8H83.7496C86.2523 125.8 88.299 123.753 88.299 121.249V106.25C88.299 103.746 86.2519 101.701 83.7496 101.701H68.7503ZM65.7992 106.25C65.7992 104.63 67.1293 103.301 68.7503 103.301H83.7496C85.3687 103.301 86.699 104.63 86.699 106.25V121.249C86.699 122.87 85.3682 124.2 83.7496 124.2H68.7503C67.1297 124.2 65.7992 122.87 65.7992 121.249V106.25Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.1981 106.25C94.1981 103.745 96.2454 101.701 98.7491 101.701H113.748C116.251 101.701 118.298 103.746 118.298 106.25L118.298 121.249C118.298 123.753 116.251 125.8 113.748 125.8H98.7483C96.2441 125.8 94.1973 123.753 94.1973 121.249L94.1981 106.25ZM98.7491 103.301C97.1282 103.301 95.798 104.63 95.798 106.25L95.7973 121.249C95.7973 122.87 97.1278 124.2 98.7483 124.2H113.748C115.367 124.2 116.698 122.87 116.698 121.249L116.698 106.25C116.698 104.63 115.368 103.301 113.748 103.301H98.7491Z"
        fill="#001F5C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1992 76.2505C64.1992 73.7458 66.2466 71.701 68.7503 71.701H83.7496C86.2519 71.701 88.299 73.7461 88.299 76.2505V91.2498C88.299 93.7542 86.2519 95.7992 83.7496 95.7992H68.7503C66.2465 95.7992 64.1992 93.7543 64.1992 91.2498V76.2505ZM68.7503 73.301C67.1293 73.301 65.7992 74.6304 65.7992 76.2505V91.2498C65.7992 92.8698 67.1293 94.1992 68.7503 94.1992H83.7496C85.3687 94.1992 86.699 92.8701 86.699 91.2498V76.2505C86.699 74.6302 85.3687 73.301 83.7496 73.301H68.7503Z"
        fill="#001F5C"
      />
      <path
        d="M123.906 46.7024C125.364 48.1604 125.364 50.5476 123.906 52.0055L113.301 62.6144C111.843 64.0724 109.455 64.0724 107.998 62.6144L97.3918 52.0055C95.9345 50.5475 95.9345 48.1603 97.3918 46.7024L107.998 36.0929C109.455 34.6357 111.843 34.6357 113.301 36.0929L123.906 46.7024Z"
        fill="#3164FA"
      />
    </svg>
  );
};
