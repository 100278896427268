import React from 'react';
import {type IconType} from '..';

export const IconDeposit: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29603 11.2001L4.09603 8.00006C3.99089 7.8573 3.92526 7.68933 3.90577 7.5131C3.88628 7.33687 3.91361 7.15862 3.98502 6.99634C4.05643 6.83405 4.16938 6.69347 4.31247 6.58878C4.45556 6.48409 4.62374 6.41899 4.80003 6.40006H7.00803V1.10406C7.00803 0.840966 7.11254 0.588647 7.29858 0.402611C7.48461 0.216575 7.73693 0.112061 8.00003 0.112061C8.26312 0.112061 8.51544 0.216575 8.70148 0.402611C8.88751 0.588647 8.99203 0.840966 8.99203 1.10406V6.40006H11.2C11.448 6.3751 11.697 6.43734 11.904 6.57606C11.9993 6.66853 12.0751 6.7792 12.1269 6.9015C12.1786 7.02381 12.2053 7.15526 12.2053 7.28806C12.2053 7.42086 12.1786 7.55231 12.1269 7.67462C12.0751 7.79692 11.9993 7.90759 11.904 8.00006L8.70403 11.2001C8.51575 11.3838 8.2631 11.4866 8.00003 11.4866C7.73696 11.4866 7.48431 11.3838 7.29603 11.2001ZM13.9515 10.2555C14.1765 10.0305 14.4817 9.90405 14.8 9.90405C15.1183 9.90405 15.4235 10.0305 15.6485 10.2555C15.8736 10.4806 16 10.7858 16 11.1041V13.1041C15.9958 13.8454 15.6995 14.5551 15.1753 15.0793C14.6511 15.6035 13.9413 15.8999 13.2 15.9041H2.8C2.05869 15.8999 1.34894 15.6035 0.824742 15.0793C0.300548 14.5551 0.00420016 13.8454 0 13.1041V11.1041C0 10.7858 0.126428 10.4806 0.351472 10.2555C0.576515 10.0305 0.88174 9.90405 1.2 9.90405C1.51826 9.90405 1.82348 10.0305 2.04853 10.2555C2.27357 10.4806 2.4 10.7858 2.4 11.1041V13.1041C2.4 13.2101 2.44214 13.3119 2.51716 13.3869C2.59217 13.4619 2.69391 13.5041 2.8 13.5041H13.2C13.3061 13.5041 13.4078 13.4619 13.4828 13.3869C13.5579 13.3119 13.6 13.2101 13.6 13.1041V11.1041C13.6 10.7858 13.7264 10.4806 13.9515 10.2555Z"
        fill="currentColor"
      />
    </svg>
  );
};
