import React from 'react';
import {type IconType} from '..';

export const IconCheckmark: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M4.56456 14.2857C4.22044 14.2811 3.88187 14.1983 3.5744 14.0437C3.26694 13.8891 2.99862 13.6666 2.7897 13.3931L0.283416 10.544C0.0986957 10.3147 0.00963036 10.0229 0.0347908 9.72946C0.0599513 9.43606 0.197396 9.16369 0.418466 8.96915C0.639536 8.7746 0.927167 8.67291 1.22139 8.68525C1.51561 8.6976 1.79371 8.82304 1.9977 9.03542L4.34627 11.7086C4.3724 11.7382 4.4044 11.7622 4.44025 11.7789C4.4761 11.7955 4.51502 11.8046 4.55455 11.8055C4.59408 11.8063 4.63336 11.799 4.66991 11.7839C4.70645 11.7688 4.73946 11.7462 4.76684 11.7177L14.024 2.06971C14.1274 1.9609 14.2512 1.87352 14.3884 1.81257C14.5256 1.75161 14.6734 1.71827 14.8235 1.71445C14.9735 1.71063 15.1229 1.7364 15.263 1.7903C15.4031 1.8442 15.5312 1.92516 15.64 2.02856C15.7488 2.13197 15.8362 2.2558 15.8971 2.39297C15.9581 2.53015 15.9914 2.67798 15.9952 2.82804C15.9991 2.9781 15.9733 3.12745 15.9194 3.26754C15.8655 3.40764 15.7845 3.53575 15.6811 3.64456L6.30742 13.5108C6.09283 13.7615 5.82502 13.9611 5.52352 14.0952C5.22202 14.2292 4.89441 14.2943 4.56456 14.2857Z"
        fill="currentColor"
      />
    </svg>
  );
};
