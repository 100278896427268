import React from 'react';
import {type IconType} from '../../../icons';

export const CurlyBangs: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M180.392 76C180.392 76 178.559 73.0081 175.347 71.8689C173.054 57.5894 172.685 50.4663 182.503 44.3614C192.321 38.2565 208.93 36.312 219.116 39.5501C229.302 42.7882 225.172 71.2638 225.172 71.2638C225.172 71.2638 221.5 70.617 219.481 75.4367C219.021 63.8695 218.015 60.1724 218.015 60.1724C218.015 60.1724 214.253 61.3032 211.041 56.7131C208.289 61.1905 201.406 61.395 197.917 56.7131C192.689 64.8877 186.634 62.0209 185.347 59.0624C182.685 63.7777 179.842 64.2409 179.842 64.2409L180.392 76Z"
        fill="#001F5C"
      />
    </svg>
  );
};
