export const TOP_ETH_SYMBOL_ADDRESSES: Record<string, string> = {
  zrx: '0xe41d2489571d322189246dafa5ebde1f4699f498',
  '1inch': '0x111111111117dc0aa78b770fa6a738034120c302',
  aave: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  ghst: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
  ads: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
  elf: '0xbf2179859fc6d5bee9bf9158632dc51678a4100e',
  aergo: '0x91af0fbb28aba7e31403cb457106ce79397fd4e6',
  ali: '0x6b0b3a982b4634ac68dd83a4dbf02311ce324181',
  alusd: '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
  ach: '0xed04915c23f00a313a544955524eb7dbd823143d',
  tlm: '0x888888848b652b3e3a0f34c96e00eec0f3a23f72',
  alpha: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
  ampl: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
  amp: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
  ankr: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
  ankreth: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
  ape: '0x4d224452801aced8b2f0aebe155379bb5d594381',
  api3: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
  ant: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
  asd: '0xff742d05420b6aca4481f635ad8341f81a6300c2',
  audio: '0x18aaa7115705e8be94bffebde57af9bfc265b998',
  rep: '0x221657776846890989a759ba2973e427dff5c9bb',
  aurabal: '0x616e8bfa43f920657b3497dbf40d6b1a02d4608d',
  aura: '0xc0c293ce456ff0ed870add98a0828dd4d2903dbf',
  ata: '0xa2120b9e674d3fc3875f415a7df52e382f141225',
  avinoc: '0xf1ca9cb74685755965c7458528a36934df52a3ef',
  axl: '0x25b24b3c47918b7962b3e49c4f468367f73cc0e0',
  axs: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
  badger: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
  bal: '0xba100000625a3754423978a60c9317c58a424e3d',
  bnt: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
  band: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
  bat: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
  beta: '0x35f67c1d929e106fdff8d1a55226afe15c34dbe2',
  bico: '0xf17e65822b568b3903685a7c9f496cf7656cc6c2',
  bfc: '0x0c7d5ae016f806603cb1782bea29ac69471cab9c',
  busd: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  bit: '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
  bgb: '0x19de6b897ed14a376dda0fe53a5420d2ac828a28',
  bmex: '0xb113c6cf239f60d380359b762e95c13817275277',
  cdt: '0xcdb37a4fbc2da5b78aa4e41a432792f9533e85cc',
  boba: '0x5f20f15d40f24dae50a72be3b5edddddfb5a5bd0',
  blid: '0x8a7adc1b690e81c758f1bd0f72dfe27ae6ec56a5',
  bone: '0x9813037ee2218799597d83d4a5b6f3b6778218d9',
  bora: '0x26fb86579e371c7aedc461b2ddef0a8628c93d3b',
  bor: '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9',
  btse: '0x666d875c600aa06ac1cf15641361dec3b00432ef',
  cre: '0x115ec79f1de567ec68b7ae7eda501b406626478e',
  ctsi: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d',
  cdai: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
  ceek: '0xb056c38f6b7dc4064367403e26424cd2c60655e1',
  celr: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
  cel: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
  xcn: '0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18',
  link: '0x514910771af9ca656af840dff83e8264ecf986ca',
  chz: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
  chr: '0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2',
  time: '0x485d17a6f1b8780392d53d64751824253011a260',
  cvc: '0x41e5560054824ea6b0732e656e3ad64e20e94e45',
  cocos: '0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c',
  cet: '0x081f67afa0ccf8c7b17540767bbe95df2ba8d97f',
  xcm: '0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25',
  ceth: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
  comp: '0xc00e94cb662c3520282e6f5717214004a7f26888',
  cusdc: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
  cusdt: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
  people: '0x7a58c0be72be218b41c608b7fe7c5bb630736c71',
  cvxcrv: '0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7',
  cvx: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
  coti: '0xddb3422497e61e13543bea06989c0789117555c5',
  cqt: '0xd417144312dbf50465b1c641d016962017ef6240',
  crts: '0x678e840c640f619e17848045d23072844224dd37',
  ctc: '0x00b7db6b4431e345eee5cc23d21e8dbc1d5cada3',
  cult: '0xf0f9d895aca5c8678f706fb8216fa22957685a13',
  crv: '0xd533a949740bb3306d119cc777fa900ba034cd52',
  core: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
  dai: '0x6b175474e89094c44da98b954eedeac495271d0f',
  dao: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad',
  dawn: '0x580c8520deda0a441522aeae0f9f7a5f29629afa',
  mana: '0x2d77f5b3efa51821ad6483adaf38ea4cb1824cc5',
  tsuka: '0xc5fb36dd2fb59d3b98deff88425a3f425ee469ed',
  dent: '0x3597bfd533a99c9aa083587b074434e61eb0a258',
  dexe: '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6',
  dka: '0x5dc60c4d5e75d22588fa17ffeb90a63e535efce0',
  dodo: '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd',
  elon: '0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
  dpx: '0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81',
  rdpx: '0x0ff5a8451a839f5f0bb3562689d9a44089738d11',
  dusk: '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
  dydx: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
  omi: '0xed35af169af46a02ee13b9d79eb57d6d68c1749e',
  efi: '0x656c00e1bcd96f256f224ad9112ff426ef053733',
  enj: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
  exrd: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414',
  elg: '0xa2085073878152ac3090ea13d1e41bd69e60dc99',
  ens: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
  ern: '0xbbc2ae13b23d715c30720f079fcd9b4a74093505',
  vgx: '0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d',
  eul: '0xd9fcd98c322942075a5c3860693e9f4f03aae07b',
  iq: '0x579cea1889991f68acc35ff5c3dd0621ff29b0c9',
  ever: '0x29d578cec46b50fa5c88a99c6a4b70184c062953',
  fet: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
  fidu: '0x6a445e9f40e0b97c92d0b8a3366cef1d67f700bf',
  flex: '0xfcf8eda095e37a41e002e266daad7efc1579bc0a',
  flexusd: '0xa774ffb4af6b0a91331c084e1aebae6ad535e6f3',
  floki: '0x67cc621ab2d086a101cff3340df0a065ac75827c',
  frax: '0x853d955acef822db058eb8505911ed77f175b99e',
  frxeth: '0x5e8422345238f34275888049021821e8e08caa1f',
  fxs: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
  fun: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
  fx: '0x8c15ef5b4b21951d50e53e4fbda8298ffad25057',
  gfarm2: '0x831091da075665168e01898c6dac004a867f1e1b',
  gala: '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da',
  gtc: '0xe138fda441fc31b36171122397a8a11d6cd2c479',
  gt: '0xe66747a101bff2dba3697199dcce5b743b454759',
  gusd: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
  gmt: '0xe3c408bd53c31c085a1746af401a4042954ff740',
  gno: '0x6810e776880c02933d47db1b9fc05908e5386b96',
  gods: '0xccc8cb5229b0ac8069c51fd58367fd1e622afd97',
  glm: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
  hft: '0xb3999f658c0391d94a37f7ff328f3fec942bcadc',
  snx: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
  hez: '0xeef9f339514298c6a857efcfc1a762af84438dee',
  hi: '0xc4f6e93aeddc11dc22268488465babcaf09399ac',
  hifi: '0x4b9278b94a1112cad404048903b8d343a810b07e',
  hot: '0x9af839687f6c94542ac5ece2e317daae355493a1',
  hum: '0x07327a00ba28d413f745c931bbe6be053b0ad2a6',
  hunt: '0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5',
  hbtc: '0x0316eb71485b0ab14103307bf65a021042c6d380',
  ht: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
  hxro: '0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3',
  ieth: '0xc383a3833a87009fd9597f8184979af5edfad019',
  rlc: '0x607f4c5bb672230e8672085532f7e901544a7375',
  ilv: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
  imx: '0x7b35ce522cb72e4077baeb96cb923a5529764a00',
  inj: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
  sure: '0xcb86c6a22cb56b6cf40cafedb06ba0df188a416e',
  iotx: '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
  ibeur: '0x96e61422b6a9ba0e068b6c5add4ffabc6a4aae27',
  kp3r: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
  keep: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
  kishu: '0xa2b4c0af19cc16a6cfacce81f192b024d625817d',
  knc: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
  lrc: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
  lcx: '0x037a54aab062628c9bbae1fdb1583c195585fe41',
  loka: '0x61e90a50137e1f645c9ef4a0d3a4f01477738406',
  leash: '0x27c70cd1946795b66be9d954418546998b546634',
  lm: '0x7bec98609cb6378d6f995e8f8097ee78376fbec9',
  leo: '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3',
  ldo: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
  lqty: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
  lusd: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
  lit: '0xfd0205066521550d7d7ab19da8f72bb004b4c341',
  lpt: '0x58b6a8a3302369daec383334672404ee733ab239',
  looks: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
  loomold: '0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0',
  lto: '0xd01409314acb3b245cea9500ece3f6fd4d70ea30',
  lyxe: '0xa8b919680258d369114910511cc87595aec0be6d',
  lyra: '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf',
  magic: '0xb0c7a3ba49c7a6eaba6cd4a96c55a1391070ac9a',
  mim: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
  emaid: '0x329c6e459ffa7475718838145e5e85802db2a303',
  mft: '0xdf2c7238198ad8b389666574f2d8bc411a4b7428',
  mkr: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  fold: '0xd084944d3c05cd115c09d072b9f44ba3e0e45921',
  mpl: '0x33349b282065b0284d756f0577fb39c158f935e6',
  pond: '0x57b946008913b82e4df85f501cbaed910e58d26c',
  mx: '0x11eef04c884e24d9b7b4760e7476d06ddf797f36',
  mask: '0xc7a8b45e184138114e6085c82936a8db93dd156a',
  mvl: '0xa849eaae994fb86afa73382e9bd88c2b6b18dc71',
  matic: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
  mc: '0x949d48eca67b17269629c7194f4b727d4ef9e5d6',
  meta: '0x364fcd7325c035cc4f2cde8b6c8d7df5e7db6589',
  mtl: '0xf433089366899d83a9f26a773d59ec7ecf30355e',
  metis: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
  mnw: '0xd3e4ba569045546d09cf021ecc5dfe42b1d7f6e4',
  multi: '0x65ef703f5594d2573eb71aaf55bc0cb548492df4',
  mxc: '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e',
  alice: '0xac51066d7bec65dc4589368da368b212745d63e8',
  nest: '0x04abeda201850ac0124161f037efd70c74ddc74c',
  nexo: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
  nkn: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb',
  nu: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
  nmr: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
  susd: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
  nxm: '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
  nym: '0x525a8f6f3ba4752868cde25164382bfbae3990e1',
  ocean: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
  okb: '0x75231f58b43240c9718dd58b4967c5114342a86c',
  ohm: '0x383518188c0c6d7730d91b2c03a03c837814a899',
  omg: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07',
  orbs: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
  oxt: '0x4575f41308ec1483f3d399aa9a2826d74da13deb',
  ousd: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
  ogn: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
  trac: '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f',
  paxg: '0x45804880de22913dafe09f4980848ece6ecbaf78',
  usdp: '0x1456688345527be1f37e9e627da0837d6f08c925',
  perp: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
  pha: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e',
  pla: '0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430',
  pltc: '0x429d83bb0dcb8cdd5311e34680adc8b12070a07f',
  pols: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa',
  poly: '0x9d93692e826a4bd9e903e2a27d7fbd1e116efdad',
  powr: '0x595832f8fc6bf59c85c527fec3740a1b7a361269',
  gal: '0x5faa989af96af85384b8a938c2ede4a7378d9875',
  prom: '0xfc82bb4ba86045af6f327323a46e80412b91b27d',
  npxs: '0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3',
  pundix: '0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38',
  qrdo: '0x4123a133ae3c521fd134d7b13a2dec35b56c2463',
  qnt: '0x4a220e6096b25eadb88358cb44068a3248254675',
  qkc: '0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664',
  rad: '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3',
  sofi: '0xb49fa25978abf9a248b8212ab4b87277682301c0',
  rail: '0xe76c6c83af64e4c60245d8c7de953df673a7a33d',
  rly: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
  rare: '0x93dfaf57d986b9ca77df9376c50878e013d9c7c8',
  btrfly: '0xc55126051b22ebb829d00368f4b12bde432de5da',
  reef: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf',
  rndr: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
  ren: '0x408e41876cccdc0f92210600ef50372656052a38',
  req: '0x8f8221afbb33998d8584a2b05749ba73c37a938a',
  rsr: '0x320623b8e4ff03373931769a31fc52a4e78b5d70',
  reth: '0xae78736cd615f374d3085123a210448e74fc6393',
  rev: '0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9',
  rbn: '0x6123b0049f904d730db3c36a31167d9d4121fa6b',
  rpl: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
  saitama: '0xce3f08e664693ca792cace4af1364d5e220827b2',
  srm: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
  seth2: '0xfe2e637202056d30016725477c5da089ab0a043a',
  ult: '0x09617f6fd6cf8a71278ec86e23bbab29c04353a7',
  shr: '0x04a5198063e45d84b1999516d3228167146417a6',
  shib: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
  agix: '0x5b7533812759b45c2b44c19e320ba2cd2681b542',
  skl: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7',
  snm: '0x46d0dac0926fa16707042cadc23f1eb4141fe86b',
  sx: '0x99fe3b1391503a1bc1788051347a1324bff41452',
  spell: '0x090185f2135308bad17527004364ebcc2d37e5f6',
  ssv: '0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54',
  maticx: '0xf03a7eb46d01d9ecaa104558c732cf82f6b6b645',
  steth: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  sfrxeth: '0xac3e018457b222d93114458476f3e3416abbe38f',
  stg: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
  eurs: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
  snt: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
  storj: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
  stmx: '0xa62cc35625b0c8dc1faea39d33625bb4c15bd71c',
  stpt: '0xde7d85157d9714eadf595045cc12ca4a5f3e2adb',
  strk: '0x74232704659ef37c08995e386a2e26cc27a8d7b1',
  sushi: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  sweat: '0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35',
  sxp: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
  chsb: '0xba9d4199fab4f26efe3551d490e3821486f135ba',
  syn: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
  xtp: '0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc',
  tel: '0x467bccd9d29f223bce8043b84e8c8b282827790f',
  trb: '0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0',
  temple: '0x470ebf5f030ed85fc1ed4c2d36b9dd02e77cf1b7',
  usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  eurt: '0xc581b735a1688071a1746c968e0798d642ede491',
  xaut: '0x68749665ff8d2d112fa859aa293f07a622782f38',
  grt: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
  ton: '0x6a6c2ada3ce053561c2fbc3ee211f23d9b8c520a',
  sand: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
  tt: '0x1e053d89e08c24aa2ce5c5b4206744dc2d7bd8f5',
  tkx: '0x667102bd3413bfeaa3dffb48fa8288819e480a88',
  tribe: '0xc7283b66eb1eb5fb86327f08e1b5816b0720212b',
  tusd: '0x0000000000085d4780b73119b644ae5ecd22b376',
  ufo: '0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b',
  uos: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
  uma: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
  uni: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
  uqc: '0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2',
  usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  utk: '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c',
  vega: '0xcb84d72e61e383767c4dfeb2d8ff7f4fb89abc6e',
  vlx: '0x8c543aed163909142695f2d2acd0d55791a9edb9',
  vra: '0xf411903cbc70a74d22900a5de66a2dda66507255',
  veri: '0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374',
  volt: '0x559ebc30b0e58a45cc9ff573f77ef1e5eb1b3e18',
  pyr: '0x430ef9263e76dae63c84292c3409d61c598e9682',
  waves: '0x1cf4592ebffd730c7dc92c1bdffdfc3b9efcf29a',
  wild: '0x2a3bff78b79a009976eea096a51a948a3dc00e34',
  woo: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
  wozx: '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f',
  wbtc: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  wcfg: '0xc221b7e65ffc80de234bbb6667abdd46593d34f0',
  wnxm: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
  xcad: '0x7659ce147d0e714454073a5dd7003544234b6aa0',
  xido: '0xf6650117017ffd48b725b4ec5a00b414097108a7',
  xmon: '0x3aada3e213abf8529606924d8d1c55cbdc70bf74',
  xsgd: '0x70e8de73ce538da2beed35d14187f6959a8eca96',
  xsushi: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
  xyo: '0x55296f69f40ea6d20e478533c15a6b08b654e758',
  yfi: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
  yfii: '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83',
  ygg: '0x25f8087ead173b73d6e8b84329989a8eea16cf73',
  zig: '0xb2617246d0c6c0087f18703d576831899ca94f01',
};
