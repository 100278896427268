import React from 'react';
import {type IconType} from '..';

export const IconCopy: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8874_15279)">
        <path
          d="M8.93336 3.15335C8.92944 3.19376 8.94103 3.23416 8.96579 3.26635C8.99055 3.29853 9.02662 3.3201 9.06669 3.32668C9.2292 3.36801 9.37241 3.46428 9.47205 3.59915C9.57168 3.73402 9.62161 3.8992 9.61336 4.06668V5.00001C9.61335 5.10914 9.63495 5.21718 9.67691 5.31791C9.71887 5.41864 9.78036 5.51007 9.85783 5.58692C9.9353 5.66378 10.0272 5.72453 10.1283 5.76568C10.2294 5.80683 10.3376 5.82756 10.4467 5.82668C10.5555 5.82757 10.6634 5.80679 10.7641 5.76556C10.8648 5.72433 10.9563 5.66348 11.0332 5.58653C11.1102 5.50959 11.171 5.41811 11.2122 5.31741C11.2535 5.21672 11.2742 5.10882 11.2734 5.00001V2.66668C11.2734 2.40261 11.1689 2.14926 10.9828 1.96192C10.7967 1.77457 10.5441 1.66844 10.28 1.66668H9.12669C9.08253 1.66807 9.04018 1.68454 9.00669 1.71335C8.9763 1.74587 8.95959 1.78884 8.96002 1.83335C8.96002 2.13335 8.96002 2.81335 8.93336 3.15335Z"
          fill="currentColor"
        />
        <path
          d="M6.00002 14.1733C6.00291 14.0605 5.98249 13.9484 5.94002 13.8438C5.89756 13.7393 5.83398 13.6446 5.75326 13.5657C5.67253 13.4869 5.5764 13.4256 5.47088 13.3856C5.36537 13.3456 5.25273 13.3278 5.14002 13.3333H2.32002C2.22628 13.3274 2.13463 13.303 2.05033 13.2616C1.96603 13.2201 1.89075 13.1624 1.82881 13.0918C1.76687 13.0212 1.71948 12.9391 1.68937 12.8501C1.65926 12.7611 1.64702 12.6671 1.65336 12.5733V4.06668C1.64636 3.89729 1.69936 3.73087 1.80302 3.59672C1.90668 3.46257 2.05435 3.36931 2.22002 3.33335C2.25941 3.32538 2.29459 3.30345 2.31909 3.2716C2.34359 3.23975 2.35576 3.20012 2.35336 3.16001C2.35336 2.64668 2.35336 2.09335 2.35336 1.82668C2.35336 1.78248 2.3358 1.74008 2.30454 1.70883C2.27328 1.67757 2.23089 1.66001 2.18669 1.66001H1.00002C0.868138 1.66001 0.737555 1.68609 0.615793 1.73677C0.494031 1.78744 0.383494 1.86169 0.290548 1.95526C0.197602 2.04882 0.124082 2.15985 0.0742207 2.28195C0.0243598 2.40404 -0.000857007 2.5348 2.22244e-05 2.66668V14C1.63562e-05 14.2641 0.104458 14.5174 0.290555 14.7048C0.476651 14.8921 0.729296 14.9983 0.993356 15H5.14002C5.25135 15.0045 5.36245 14.9864 5.46662 14.9469C5.5708 14.9074 5.6659 14.8472 5.74623 14.77C5.82656 14.6928 5.89045 14.6001 5.93406 14.4976C5.97768 14.3951 6.00011 14.2848 6.00002 14.1733Z"
          fill="currentColor"
        />
        <path
          d="M7.96002 2.98001V1.64668C7.95828 1.55943 7.92239 1.47635 7.86006 1.41527C7.79774 1.3542 7.71395 1.31999 7.62669 1.32001H7.12002C7.07947 1.31893 7.04058 1.30366 7.01013 1.27686C6.97967 1.25006 6.95958 1.21343 6.95336 1.17335C6.90336 0.860115 6.74333 0.574968 6.50201 0.369111C6.26069 0.163253 5.95388 0.0501709 5.63669 0.0501709C5.31949 0.0501709 5.01269 0.163253 4.77137 0.369111C4.53005 0.574968 4.37002 0.860115 4.32002 1.17335C4.3183 1.21638 4.29998 1.25708 4.26891 1.28691C4.23784 1.31674 4.19643 1.33338 4.15336 1.33335H3.64669C3.56352 1.33673 3.48479 1.37173 3.42655 1.4312C3.36832 1.49067 3.33499 1.57013 3.33336 1.65335V2.98668C3.33247 3.03014 3.34026 3.07333 3.35628 3.11374C3.3723 3.15414 3.39622 3.19095 3.42664 3.22199C3.45707 3.25303 3.49338 3.27769 3.53345 3.29452C3.57353 3.31136 3.61656 3.32002 3.66002 3.32001H7.66002C7.74369 3.31163 7.8211 3.27193 7.87674 3.20888C7.93237 3.14583 7.96212 3.06407 7.96002 2.98001Z"
          fill="currentColor"
        />
        <path
          d="M12.6667 11.5133H10C9.86741 11.5133 9.74024 11.566 9.64647 11.6598C9.5527 11.7536 9.50002 11.8807 9.50002 12.0133C9.50002 12.146 9.5527 12.2731 9.64647 12.3669C9.74024 12.4607 9.86741 12.5133 10 12.5133H12.6667C12.7993 12.5133 12.9265 12.4607 13.0202 12.3669C13.114 12.2731 13.1667 12.146 13.1667 12.0133C13.1667 11.8807 13.114 11.7536 13.0202 11.6598C12.9265 11.566 12.7993 11.5133 12.6667 11.5133Z"
          fill="currentColor"
        />
        <path
          d="M9.52669 9.69335C9.52842 9.82542 9.58165 9.95159 9.67505 10.045C9.76844 10.1384 9.89462 10.1916 10.0267 10.1933H11.6867C11.8193 10.1933 11.9465 10.1407 12.0402 10.0469C12.134 9.95313 12.1867 9.82595 12.1867 9.69335C12.1867 9.56074 12.134 9.43356 12.0402 9.33979C11.9465 9.24603 11.8193 9.19335 11.6867 9.19335H10C9.87265 9.20174 9.75314 9.2579 9.66538 9.3506C9.57762 9.4433 9.52809 9.5657 9.52669 9.69335Z"
          fill="currentColor"
        />
        <path
          d="M15.6667 8.97335C15.667 8.62125 15.528 8.28333 15.28 8.03335L14.34 7.10001C14.0911 6.84962 13.7531 6.70817 13.4 6.70668H8.32669C7.97307 6.70668 7.63393 6.84715 7.38388 7.0972C7.13383 7.34725 6.99336 7.68639 6.99336 8.04001V14.6667C6.99336 15.0203 7.13383 15.3594 7.38388 15.6095C7.63393 15.8595 7.97307 16 8.32669 16H14.3267C14.6803 16 15.0194 15.8595 15.2695 15.6095C15.5195 15.3594 15.66 15.0203 15.66 14.6667L15.6667 8.97335ZM8.33336 8.70668C8.33336 8.52987 8.40359 8.3603 8.52862 8.23527C8.65364 8.11025 8.82321 8.04001 9.00002 8.04001H13.1267C13.2144 8.03951 13.3014 8.05632 13.3826 8.0895C13.4639 8.12267 13.5377 8.17156 13.6 8.23335L14.1467 8.78001C14.2699 8.90419 14.3393 9.07179 14.34 9.24668V14C14.34 14.1768 14.2698 14.3464 14.1448 14.4714C14.0197 14.5964 13.8502 14.6667 13.6734 14.6667H9.00669C8.82988 14.6667 8.66031 14.5964 8.53528 14.4714C8.41026 14.3464 8.34002 14.1768 8.34002 14L8.33336 8.70668Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8874_15279">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
