import React from 'react';
import {type IconType} from '../../../icons';

export const SendingLove: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M127.256 44.154C127.141 43.1595 127.833 41.8505 129.191 41.6931C129.95 41.6051 130.669 42.1473 131.224 42.6774C131.642 42.0346 132.218 41.3421 132.977 41.2542C134.335 41.0968 135.309 42.2126 135.424 43.207C135.673 45.3571 133.511 47.6307 131.968 49.0972C130.131 48.0226 127.505 46.3043 127.256 44.154Z"
        fill="#3164FA"
      />
      <path
        d="M143.71 49.4217C141.377 48.5407 139.159 50.083 138.514 51.7916C137.119 55.4858 139.938 60.4679 142.017 63.7469C145.744 62.6602 151.152 60.7841 152.547 57.0902C153.192 55.3818 152.547 52.7582 150.214 51.8772C148.911 51.3852 147.397 52.0254 146.181 52.7175C145.726 51.3946 145.013 49.9137 143.71 49.4217Z"
        fill="#3164FA"
      />
      <path
        d="M199.894 120.335C189.938 120.301 187.638 118.432 187.134 117.765C187.025 117.62 187 117.439 187 117.258V110H174.478C169.243 110.001 164.118 111.511 159.718 114.351L130.481 133.219L132.304 127.297C132.756 125.828 131.446 124.366 129.921 124.555C124.772 125.191 115.047 125.556 109.873 120.36C108.776 119.258 106.763 119.167 105.936 120.484C102.527 125.916 95.1757 137.75 89.7055 147.406C82.3499 160.39 76.5305 176.984 90.5098 185.351C104.489 193.719 117.978 191.441 133.578 180.063C149.178 168.685 161.202 158.249 161.202 158.249V198.89C161.202 201.099 162.993 202.891 165.202 202.891L234.616 202.891C236.825 202.891 238.616 201.099 238.616 198.89V158.249C238.616 158.249 250.641 168.685 266.241 180.063C281.841 191.441 295.329 193.719 309.309 185.351C323.288 176.984 317.468 160.39 310.113 147.406C304.643 137.75 297.291 125.916 293.882 120.484C293.055 119.167 291.042 119.258 289.945 120.36C284.771 125.556 275.046 125.191 269.897 124.555C268.373 124.366 267.063 125.828 267.515 127.297L269.337 133.219L240.1 114.351C235.7 111.511 230.576 110.001 225.34 110H212.818V117.258C212.818 117.439 212.793 117.62 212.684 117.765C212.179 118.433 209.876 120.305 199.894 120.335Z"
        fill="#3164FA"
      />
      <path
        d="M114.95 61.6667C114.745 59.4196 116.379 56.5088 119.447 56.2295C121.161 56.0735 122.752 57.3349 123.971 58.5599C124.949 57.1349 126.286 55.6068 128 55.4508C131.068 55.1714 133.201 57.7395 133.405 59.9864C133.848 64.8445 128.849 69.8469 125.292 73.0654C121.212 70.5423 115.392 66.5252 114.95 61.6667Z"
        fill="#3164FA"
      />
      <path
        d="M269.264 66.8699C269.145 65.5655 270.093 63.8759 271.874 63.7138C272.869 63.6232 273.793 64.3554 274.5 65.0665C275.068 64.2393 275.844 63.3523 276.839 63.2618C278.62 63.0996 279.858 64.5903 279.976 65.8945C280.233 68.7144 277.331 71.6181 275.267 73.4864C272.899 72.0218 269.52 69.69 269.264 66.8699Z"
        fill="#3164FA"
      />
      <path
        d="M271.775 51.6967C270.937 51.265 270.038 51.7479 269.722 52.3613C269.038 53.6876 269.881 55.6796 270.519 57.0004C271.965 56.7539 274.076 56.2838 274.76 54.9576C275.076 54.3442 274.947 53.3315 274.11 52.8998C273.642 52.6588 273.046 52.8335 272.56 53.0407C272.446 52.5245 272.243 51.9378 271.775 51.6967Z"
        fill="#3164FA"
      />
      <path
        d="M251.507 58.01C248.714 58.9212 247.813 61.9737 248.481 64.019C249.923 68.4413 256.186 70.9397 260.514 72.4189C263.137 68.672 266.722 62.9607 265.279 58.5388C264.612 56.4936 262.085 54.5592 259.292 55.4704C257.732 55.9792 256.813 57.6835 256.207 59.2158C254.814 58.3359 253.067 57.5012 251.507 58.01Z"
        fill="#3164FA"
      />
      <path
        d="M292.793 74.8966C292.851 75.3347 292.542 75.7362 292.104 75.7933C287.796 76.3551 284.341 79.1344 281.746 82.5009C279.154 85.8635 277.495 89.7196 276.768 92.2231C276.645 92.6474 276.201 92.8915 275.777 92.7683C275.353 92.6451 275.109 92.2013 275.232 91.777C276.005 89.1138 277.746 85.0699 280.479 81.5241C283.209 77.9824 287.004 74.845 291.897 74.2068C292.335 74.1496 292.736 74.4585 292.793 74.8966Z"
        fill="#001F5C"
      />
      <path
        d="M298.741 81.1971C298.908 81.6061 298.712 82.0732 298.303 82.2405C289.733 85.7463 285.344 92.4656 284.246 95.29C284.086 95.7018 283.622 95.9058 283.21 95.7456C282.798 95.5855 282.594 95.1219 282.755 94.7101C283.989 91.5345 288.667 84.4537 297.697 80.7596C298.106 80.5923 298.573 80.7882 298.741 81.1971Z"
        fill="#001F5C"
      />
      <path
        d="M95.6338 88.7112C95.241 88.5089 95.0866 88.0264 95.2889 87.6337C95.4913 87.2409 95.9737 87.0865 96.3665 87.2889C104.792 91.6291 108.314 96.788 109.947 101.685C110.754 104.108 111.09 106.44 111.349 108.502C111.377 108.73 111.405 108.955 111.432 109.175C111.654 110.977 111.842 112.495 112.259 113.747C112.97 115.879 114.561 117.231 116.354 118.003C118.164 118.782 120.106 118.934 121.361 118.712C121.796 118.635 122.211 118.926 122.288 119.361C122.365 119.796 122.074 120.211 121.639 120.288C120.061 120.566 117.803 120.368 115.721 119.472C113.622 118.569 111.631 116.921 110.741 114.253C110.269 112.838 110.062 111.146 109.845 109.379C109.817 109.154 109.79 108.928 109.761 108.701C109.504 106.654 109.183 104.455 108.429 102.191C106.936 97.7121 103.709 92.871 95.6338 88.7112Z"
        fill="#001F5C"
      />
      <path
        d="M303.866 88.7112C304.259 88.5089 304.414 88.0264 304.211 87.6337C304.009 87.2409 303.527 87.0865 303.134 87.2889C294.709 91.6291 291.186 96.788 289.554 101.685C288.746 104.108 288.41 106.44 288.152 108.502C288.123 108.73 288.095 108.955 288.068 109.175C287.846 110.976 287.658 112.495 287.241 113.747C286.531 115.879 284.939 117.231 283.146 118.003C281.336 118.782 279.394 118.934 278.139 118.712C277.704 118.635 277.289 118.926 277.212 119.361C277.136 119.796 277.426 120.211 277.861 120.288C279.44 120.566 281.697 120.368 283.779 119.472C285.878 118.569 287.87 116.921 288.759 114.253C289.231 112.838 289.438 111.146 289.655 109.38C289.683 109.155 289.711 108.928 289.739 108.701C289.996 106.654 290.317 104.455 291.072 102.191C292.564 97.7121 295.792 92.871 303.866 88.7112Z"
        fill="#001F5C"
      />
      <path
        d="M101.197 82.2405C100.788 82.0732 100.592 81.6061 100.76 81.1971C100.927 80.7882 101.394 80.5923 101.803 80.7596C110.833 84.4537 115.511 91.5345 116.746 94.7101C116.906 95.1219 116.702 95.5855 116.29 95.7456C115.878 95.9058 115.415 95.7018 115.255 95.29C114.156 92.4656 109.767 85.7463 101.197 82.2405Z"
        fill="#001F5C"
      />
      <path
        d="M107.397 75.7933C106.959 75.7362 106.65 75.3347 106.707 74.8966C106.764 74.4585 107.165 74.1496 107.604 74.2068C112.496 74.845 116.291 77.9824 119.021 81.5241C121.754 85.0699 123.495 89.1138 124.268 91.777C124.392 92.2013 124.147 92.6451 123.723 92.7683C123.299 92.8915 122.855 92.6474 122.732 92.2231C122.005 89.7196 120.346 85.8635 117.754 82.5009C115.159 79.1344 111.704 76.3551 107.397 75.7933Z"
        fill="#001F5C"
      />
      <path
        d="M269.313 80.6025C271.467 80.773 273.346 81.6026 274.444 82.3344C274.812 82.5794 274.911 83.0761 274.666 83.4438C274.421 83.8114 273.924 83.9107 273.556 83.6656C272.654 83.0641 271.033 82.3436 269.187 82.1975C267.364 82.0532 265.344 82.4672 263.535 84.0946C261.701 85.7451 260.763 87.9421 260.348 90.066C259.987 91.9119 260.031 93.6568 260.199 94.8404C261.108 94.9975 262.221 94.9094 263.451 94.2863C264.92 93.5426 266.611 92.008 268.309 89.0969C268.532 88.7153 269.022 88.5864 269.403 88.809C269.785 89.0316 269.914 89.5215 269.691 89.9031C267.889 92.992 265.997 94.7908 264.174 95.7138C263.025 96.2957 261.925 96.5179 260.932 96.5079C262.007 97.1436 262.968 98.0121 263.665 99.0557C263.911 99.4232 263.812 99.9199 263.444 100.165C263.077 100.411 262.58 100.312 262.335 99.9443C261.449 98.6173 259.987 97.6085 258.467 97.1658C257.078 96.7614 255.76 96.857 254.805 97.4619C254.736 99.9043 255.445 104.187 257.649 108.293C259.955 112.59 263.869 116.643 270.194 118.224C270.623 118.331 270.883 118.765 270.776 119.194C270.669 119.623 270.235 119.883 269.806 119.776C262.951 118.063 258.709 113.652 256.239 109.05C253.783 104.473 253.048 99.6577 253.225 96.986C253.24 96.7629 253.347 96.5562 253.521 96.4159C254.972 95.2468 256.916 95.1037 258.699 95.57C258.441 94.2172 258.328 92.0555 258.778 89.759C259.237 87.4079 260.299 84.8549 262.465 82.9054C264.657 80.9328 267.136 80.4301 269.313 80.6025Z"
        fill="#001F5C"
      />
      <path
        d="M125.056 82.3344C126.154 81.6026 128.033 80.773 130.187 80.6025C132.364 80.4301 134.843 80.9328 137.035 82.9054C139.201 84.8549 140.263 87.4079 140.723 89.759C141.172 92.0555 141.059 94.2172 140.801 95.57C142.584 95.1037 144.529 95.2468 145.979 96.4159C146.153 96.5562 146.26 96.7629 146.275 96.986C146.452 99.6577 145.717 104.473 143.261 109.05C140.791 113.652 136.549 118.063 129.694 119.776C129.265 119.883 128.831 119.623 128.724 119.194C128.617 118.765 128.878 118.331 129.306 118.224C135.632 116.643 139.545 112.59 141.851 108.293C144.055 104.187 144.764 99.9043 144.696 97.4619C143.74 96.857 142.422 96.7614 141.033 97.1658C139.513 97.6085 138.052 98.6173 137.165 99.9443C136.92 100.312 136.423 100.411 136.056 100.165C135.688 99.9199 135.589 99.4232 135.835 99.0557C136.532 98.0121 137.493 97.1436 138.568 96.5079C137.575 96.5179 136.476 96.2957 135.326 95.7138C133.503 94.7908 131.611 92.992 129.809 89.9031C129.586 89.5215 129.715 89.0316 130.097 88.809C130.479 88.5864 130.969 88.7153 131.191 89.0969C132.889 92.008 134.58 93.5426 136.049 94.2863C137.28 94.9094 138.392 94.9975 139.301 94.8404C139.469 93.6568 139.513 91.9119 139.152 90.066C138.737 87.9421 137.799 85.7451 135.965 84.0946C134.157 82.4672 132.136 82.0532 130.313 82.1975C128.467 82.3436 126.846 83.0641 125.944 83.6656C125.576 83.9107 125.08 83.8114 124.834 83.4438C124.589 83.0761 124.689 82.5794 125.056 82.3344Z"
        fill="#001F5C"
      />
    </svg>
  );
};
