import React from 'react';
import {type IconType} from '..';

export const IconReload: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_301_332)">
        <path
          d="M13.6 7.99998C13.2818 7.99998 12.9765 8.12641 12.7515 8.35146C12.5264 8.5765 12.4 8.88172 12.4 9.19998C12.4 10.0702 12.142 10.9209 11.6585 11.6445C11.175 12.3681 10.4878 12.932 9.68382 13.2651C8.87983 13.5981 7.99513 13.6852 7.14162 13.5154C6.2881 13.3457 5.5041 12.9266 4.88875 12.3113C4.27339 11.6959 3.85434 10.9119 3.68456 10.0584C3.51479 9.20487 3.60192 8.32017 3.93495 7.51618C4.26797 6.71218 4.83193 6.025 5.55551 5.54152C6.27908 5.05804 7.12978 4.79998 8.00002 4.79998H8.40002V6.39998C8.40004 6.55829 8.44703 6.71303 8.53504 6.84461C8.62305 6.9762 8.74812 7.07871 8.89442 7.13918C8.99123 7.17957 9.09512 7.20024 9.20002 7.19998C9.41034 7.19825 9.61153 7.11375 9.76002 6.96478L12.5664 4.15998C12.7164 4.00996 12.8006 3.80651 12.8006 3.59438C12.8006 3.38225 12.7164 3.17881 12.5664 3.02878L9.76002 0.233584C9.64781 0.123591 9.50569 0.0490771 9.3514 0.0193403C9.19711 -0.0103965 9.03748 0.0059605 8.89243 0.0663702C8.74738 0.12678 8.62333 0.228567 8.53576 0.359031C8.44819 0.489495 8.40098 0.642858 8.40002 0.799984V2.39998H8.00002C6.6551 2.39998 5.34039 2.7988 4.22214 3.54599C3.10388 4.29318 2.23231 5.3552 1.71763 6.59774C1.20296 7.84027 1.0683 9.20753 1.33068 10.5266C1.59305 11.8457 2.24069 13.0573 3.19169 14.0083C4.14269 14.9593 5.35433 15.6069 6.6734 15.8693C7.99247 16.1317 9.35972 15.997 10.6023 15.4824C11.8448 14.9677 12.9068 14.0961 13.654 12.9779C14.4012 11.8596 14.8 10.5449 14.8 9.19998C14.8 8.88172 14.6736 8.5765 14.4485 8.35146C14.2235 8.12641 13.9183 7.99998 13.6 7.99998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_301_332">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
