import React from 'react';
import {type IconType} from '../../../icons';

export const Curly: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M170.922 76C170.922 76 166 74.9739 166 67.9516C166 60.9293 173.913 58.0283 173.913 58.0283C173.913 58.0283 168.803 51.4747 176.328 41.7373C183.853 31.9998 196.955 31.4424 203.814 36.0282C209.202 31.5353 216.544 32.2827 221.188 36.6827C225.831 41.0828 224.907 48.6667 222.675 52.2221C235.31 56.0606 233.086 67.9516 227.784 73.4748C224.453 69.4506 219.422 74.1294 219.422 74.1294V64.4045C219.422 64.4045 210.41 64.3117 203.909 51.4874C195.36 69.1804 180.342 63.8134 180.342 63.8134L179.58 75.4553C179.58 75.4553 179.751 71.9885 175.383 71.9885C171.476 71.9758 170.922 76 170.922 76Z"
        fill="#001F5C"
      />
    </svg>
  );
};
