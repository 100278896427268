import React from 'react';
import {type IconType} from '../../../icons';

export const Oldschool: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M173.489 71.4646C173.489 71.4646 171 64.5776 171 50.018C171 35.4584 182.303 33 204.333 33C226.364 33 228.852 38.4094 227.802 52.7737C226.753 67.138 226.174 71.9572 226.174 71.9572C226.174 71.9572 221.577 70.9765 220.233 74.8105C218.889 78.6446 220.902 72.645 218.029 64.8749C199.066 64.5776 195.619 52.7826 195.619 52.7826C195.619 52.7826 179.814 58.4893 178.855 75.5072C176.746 71.5623 173.489 71.4646 173.489 71.4646Z"
        fill="#001F5C"
      />
    </svg>
  );
};
