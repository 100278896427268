import React from 'react';
import {type IconType} from '../../icons';

export const Goal: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 160 160"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.3521 79.1251C87.3521 76.9723 89.0973 75.2271 91.2501 75.2271H116C118.153 75.2271 119.898 76.9723 119.898 79.1251V119.25C119.898 121.403 118.153 123.148 116 123.148H40.0001C37.8473 123.148 36.1021 121.403 36.1021 119.25V106.672C36.1021 104.519 37.8473 102.774 40.0001 102.774H60.2657C61.4266 102.774 62.3677 101.833 62.3677 100.672V93.2189C62.3677 91.066 64.1129 89.3208 66.2657 89.3208H85.2501C86.411 89.3208 87.3521 88.3797 87.3521 87.2189V79.1251ZM91.2501 77.0232C90.0893 77.0232 89.1482 77.9643 89.1482 79.1251V87.2189C89.1482 89.3717 87.403 91.1169 85.2501 91.1169H66.2657C65.1049 91.1169 64.1638 92.058 64.1638 93.2189V100.672C64.1638 102.825 62.4186 104.57 60.2657 104.57H40.0001C38.8393 104.57 37.8982 105.511 37.8982 106.672V119.25C37.8982 120.411 38.8393 121.352 40.0001 121.352H116C117.161 121.352 118.102 120.411 118.102 119.25V79.1251C118.102 77.9643 117.161 77.0232 116 77.0232H91.2501Z"
        fill="#001F5C"
      />
      <path
        d="M105.636 32.3274V75.8147L100.983 75.8144V57.3396H75.8539C75.0659 57.338 74.3315 56.9371 73.9038 56.2749C73.4761 55.6119 73.4137 54.7785 73.7366 54.0587L77.855 44.9033L73.7828 36.8409C73.3957 36.1041 73.4314 35.2171 73.8761 34.5144C74.2997 33.8278 75.0479 33.4099 75.8537 33.4091H100.983V32.3274C100.983 31.042 102.025 30 103.309 30C104.595 30 105.636 31.0419 105.636 32.3274Z"
        fill="#3164FA"
      />
    </svg>
  );
};
