import React from 'react';
import {type IconType} from '..';

export const IconExpand: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 0H15.2C15.4121 0 15.6156 0.0842855 15.7656 0.234315C15.9157 0.384344 16 0.587827 16 0.8V5.2C15.9999 5.3583 15.9529 5.51304 15.8649 5.64463C15.7769 5.77621 15.6519 5.87873 15.5056 5.9392C15.4087 5.97958 15.3048 6.00025 15.2 6C15.0943 6.00061 14.9895 5.98028 14.8918 5.94019C14.794 5.90009 14.7052 5.84103 14.6304 5.7664L13.28 4.4144L10.848 6.848C10.7381 6.9659 10.6056 7.06046 10.4584 7.12605C10.3112 7.19164 10.1523 7.2269 9.99119 7.22975C9.83006 7.23259 9.67002 7.20295 9.5206 7.1426C9.37117 7.08224 9.23544 6.99241 9.12149 6.87846C9.00754 6.76451 8.91771 6.62878 8.85736 6.47936C8.797 6.32994 8.76736 6.16989 8.77021 6.00876C8.77305 5.84764 8.80832 5.68874 8.8739 5.54154C8.93949 5.39434 9.03405 5.26186 9.15195 5.152L11.592 2.7168L10.24 1.3664C10.1282 1.2549 10.0519 1.11281 10.0207 0.958031C9.98948 0.803252 10.0048 0.642703 10.0647 0.496606C10.1245 0.350509 10.2263 0.225396 10.3572 0.137025C10.488 0.0486544 10.6421 0.000977852 10.8 0ZM2.72 11.5856L5.152 9.15202C5.37948 8.94005 5.68035 8.82466 5.99124 8.83014C6.30212 8.83563 6.59873 8.96157 6.8186 9.18143C7.03846 9.40129 7.16439 9.6979 7.16988 10.0088C7.17536 10.3197 7.05997 10.6205 6.848 10.848L4.4144 13.28L5.76 14.6336C5.87179 14.7451 5.94809 14.8872 5.97928 15.042C6.01047 15.1968 5.99517 15.3573 5.93529 15.5034C5.87541 15.6495 5.77364 15.7746 5.6428 15.863C5.51196 15.9514 5.35789 15.999 5.2 16H0.8C0.587827 16 0.384344 15.9157 0.234315 15.7657C0.0842855 15.6157 0 15.4122 0 15.2V10.8C0.000977852 10.6421 0.0486544 10.4881 0.137025 10.3572C0.225396 10.2264 0.350509 10.1246 0.496606 10.0647C0.642703 10.0049 0.803252 9.98955 0.958031 10.0207C1.11281 10.0519 1.2549 10.1282 1.3664 10.24L2.72 11.5856Z"
        fill="currentColor"
      />
    </svg>
  );
};
