import React from 'react';
import {type IconType} from '..';

export const IconWarning: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 17"
      {...props}
    >
      <path
        d="M15.5968 11.7304L9.9968 1.68079C9.79978 1.32393 9.51069 1.02643 9.15964 0.819242C8.8086 0.612059 8.40842 0.502777 8.0008 0.502777C7.59317 0.502777 7.19299 0.612059 6.84195 0.819242C6.4909 1.02643 6.20181 1.32393 6.0048 1.68079L0.401596 11.7384C0.132444 12.2276 -0.00458609 12.7784 0.00401274 13.3367C0.0126116 13.8949 0.166543 14.4413 0.450632 14.9219C0.734721 15.4026 1.13916 15.8009 1.62408 16.0776C2.10901 16.3543 2.65767 16.4999 3.216 16.5H12.7808C13.34 16.5 13.8896 16.3542 14.3753 16.077C14.861 15.7997 15.266 15.4006 15.5503 14.919C15.8346 14.4375 15.9885 13.8901 15.9966 13.3309C16.0048 12.7717 15.867 12.22 15.5968 11.7304ZM7.2 5.29999C7.2 5.08781 7.28428 4.88433 7.43431 4.7343C7.58434 4.58427 7.78782 4.49999 8 4.49999C8.21217 4.49999 8.41565 4.58427 8.56568 4.7343C8.71571 4.88433 8.8 5.08781 8.8 5.29999V8.49999C8.8 8.71216 8.71571 8.91564 8.56568 9.06567C8.41565 9.2157 8.21217 9.29999 8 9.29999C7.78782 9.29999 7.58434 9.2157 7.43431 9.06567C7.28428 8.91564 7.2 8.71216 7.2 8.49999V5.29999ZM8 14.1C7.68355 14.1 7.3742 14.0061 7.11108 13.8303C6.84796 13.6545 6.64289 13.4046 6.52179 13.1123C6.40069 12.8199 6.369 12.4982 6.43074 12.1878C6.49248 11.8775 6.64486 11.5924 6.86863 11.3686C7.09239 11.1449 7.37748 10.9925 7.68785 10.9307C7.99822 10.869 8.31993 10.9007 8.61229 11.0218C8.90465 11.1429 9.15454 11.348 9.33035 11.6111C9.50616 11.8742 9.6 12.1835 9.6 12.5C9.6 12.9243 9.43142 13.3313 9.13137 13.6314C8.83131 13.9314 8.42434 14.1 8 14.1Z"
        fill="currentColor"
      />
    </svg>
  );
};
