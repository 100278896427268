import React from 'react';
import {type IconType} from '..';

export const IconChevronLeft: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6953 3.29243C10.8824 3.47973 10.9875 3.73374 10.9875 3.99858C10.9875 4.26342 10.8824 4.51743 10.6953 4.70473L7.40894 7.99376L10.6953 11.2828C10.7906 11.3749 10.8667 11.4851 10.919 11.607C10.9713 11.7288 10.9988 11.8599 11 11.9925C11.0011 12.1251 10.9759 12.2567 10.9257 12.3794C10.8755 12.5022 10.8014 12.6137 10.7077 12.7075C10.614 12.8012 10.5026 12.8754 10.3799 12.9256C10.2573 12.9758 10.1258 13.0011 9.99334 13C9.86082 12.9988 9.72987 12.9713 9.60811 12.9189C9.48635 12.8666 9.37623 12.7905 9.28417 12.6951L5.2922 8.6999C5.1051 8.5126 5 8.2586 5 7.99376C5 7.72891 5.1051 7.47491 5.2922 7.28761L9.28417 3.29243C9.47132 3.10519 9.72511 3 9.98974 3C10.2544 3 10.5082 3.10519 10.6953 3.29243Z"
        fill="currentColor"
      />
    </svg>
  );
};
