import React from 'react';
import {type IconType} from '..';

export const IconAdd: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M14 7H9.25C9.1837 7 9.12011 6.97366 9.07322 6.92678C9.02634 6.87989 9 6.8163 9 6.75V2C9 1.73478 8.89464 1.48043 8.70711 1.29289C8.51957 1.10536 8.26522 1 8 1C7.73478 1 7.48043 1.10536 7.29289 1.29289C7.10536 1.48043 7 1.73478 7 2V6.75C7 6.8163 6.97366 6.87989 6.92678 6.92678C6.87989 6.97366 6.8163 7 6.75 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8C1 8.26522 1.10536 8.51957 1.29289 8.70711C1.48043 8.89464 1.73478 9 2 9H6.75C6.8163 9 6.87989 9.02634 6.92678 9.07322C6.97366 9.12011 7 9.1837 7 9.25V14C7 14.2652 7.10536 14.5196 7.29289 14.7071C7.48043 14.8946 7.73478 15 8 15C8.26522 15 8.51957 14.8946 8.70711 14.7071C8.89464 14.5196 9 14.2652 9 14V9.25C9 9.1837 9.02634 9.12011 9.07322 9.07322C9.12011 9.02634 9.1837 9 9.25 9H14C14.2652 9 14.5196 8.89464 14.7071 8.70711C14.8946 8.51957 15 8.26522 15 8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7Z"
        fill="currentColor"
      />
    </svg>
  );
};
