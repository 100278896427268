import React from 'react';
import {type IconType} from '../../../icons';

export const LargeStylizedXl: IconType = ({
  height = 160,
  width = 160,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.2 72C170.2 71.5581 170.558 71.2 171 71.2H197.548C197.99 71.2 198.348 71.5581 198.348 72V75.6567H200.652V72C200.652 71.5581 201.01 71.2 201.452 71.2H228C228.442 71.2 228.8 71.5581 228.8 72C228.8 77.1854 224.596 81.389 219.411 81.389H201.452C201.01 81.389 200.652 81.0308 200.652 80.589V77.2567H198.348V80.589C198.348 81.0308 197.99 81.389 197.548 81.389H179.589C174.404 81.389 170.2 77.1854 170.2 72ZM202.252 77.2567V79.789H219.411C223.443 79.789 226.759 76.7259 227.159 72.8H202.252V75.6567H202.74C203.181 75.6567 203.54 76.0149 203.54 76.4567C203.54 76.8986 203.181 77.2567 202.74 77.2567H202.252ZM196.748 72.8V75.6567H196.329C195.887 75.6567 195.529 76.0149 195.529 76.4567C195.529 76.8986 195.887 77.2567 196.329 77.2567H196.748V79.789H179.589C175.557 79.789 172.241 76.7259 171.841 72.8H196.748Z"
        fill="#001F5C"
      />
    </svg>
  );
};
