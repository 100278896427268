import React from 'react';
import {type IconType} from '..';

export const IconClose: IconType = ({height = 16, width = 16, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M10.0787 8.68929C10.0554 8.66606 10.037 8.63848 10.0244 8.6081C10.0118 8.57773 10.0053 8.54517 10.0053 8.51229C10.0053 8.4794 10.0118 8.44684 10.0244 8.41647C10.037 8.3861 10.0554 8.35851 10.0787 8.33529L14.6947 3.71929C14.7902 3.62704 14.8664 3.5167 14.9188 3.39469C14.9712 3.27269 14.9988 3.14147 15 3.00869C15.0011 2.87591 14.9758 2.74423 14.9255 2.62133C14.8753 2.49844 14.801 2.38679 14.7071 2.29289C14.6132 2.199 14.5016 2.12475 14.3787 2.07447C14.2558 2.02419 14.1241 1.99888 13.9913 2.00004C13.8585 2.00119 13.7273 2.02878 13.6053 2.08119C13.4833 2.1336 13.373 2.20978 13.2807 2.30529L8.66471 6.92129C8.64149 6.94457 8.6139 6.96304 8.58353 6.97564C8.55316 6.98825 8.5206 6.99473 8.48771 6.99473C8.45483 6.99473 8.42227 6.98825 8.39189 6.97564C8.36152 6.96304 8.33393 6.94457 8.31071 6.92129L3.69471 2.30529C3.50611 2.12313 3.25351 2.02234 2.99131 2.02461C2.72911 2.02689 2.4783 2.13206 2.29289 2.31747C2.10749 2.50288 2.00232 2.75369 2.00004 3.01589C1.99776 3.27808 2.09855 3.53069 2.28071 3.71929L6.89671 8.33529C6.91999 8.35851 6.93847 8.3861 6.95107 8.41647C6.96367 8.44684 6.97016 8.4794 6.97016 8.51229C6.97016 8.54517 6.96367 8.57773 6.95107 8.6081C6.93847 8.63848 6.91999 8.66606 6.89671 8.68929L2.28071 13.3053C2.09855 13.4939 1.99776 13.7465 2.00004 14.0087C2.00232 14.2709 2.10749 14.5217 2.29289 14.7071C2.4783 14.8925 2.72911 14.9977 2.99131 15C3.25351 15.0022 3.50611 14.9014 3.69471 14.7193L8.31071 10.1033C8.33393 10.08 8.36152 10.0615 8.39189 10.0489C8.42227 10.0363 8.45483 10.0298 8.48771 10.0298C8.5206 10.0298 8.55316 10.0363 8.58353 10.0489C8.6139 10.0615 8.64149 10.08 8.66471 10.1033L13.2807 14.7193C13.4693 14.9014 13.7219 15.0022 13.9841 15C14.2463 14.9977 14.4971 14.8925 14.6825 14.7071C14.8679 14.5217 14.9731 14.2709 14.9754 14.0087C14.9777 13.7465 14.8769 13.4939 14.6947 13.3053L10.0787 8.68929Z"
        fill="currentColor"
      />
    </svg>
  );
};
