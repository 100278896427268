import React from 'react';
import {type IconType} from '../../../icons';

export const Thuglife: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M174.173 70.0001C174.173 69.6152 174.485 69.3032 174.87 69.3032H195.838C196.222 69.3032 196.534 69.6152 196.534 70.0001V73.8792H200.319V70.0001C200.319 69.6152 200.631 69.3032 201.016 69.3032H222C222.385 69.3032 222.697 69.615 222.697 69.9998C222.697 71.4383 222.415 72.8629 221.864 74.1921C221.314 75.5213 220.508 76.729 219.491 77.7464C218.474 78.7638 217.266 79.5709 215.937 80.1215C214.608 80.6722 213.184 80.9556 211.745 80.9556H201.016C200.631 80.9556 200.319 80.6436 200.319 80.2587V75.4792H196.534V80.2546C196.534 80.6395 196.222 80.9515 195.838 80.9515H185.129C183.69 80.952 182.266 80.6692 180.937 80.1191C179.607 79.569 178.4 78.7624 177.382 77.7454C176.365 76.7284 175.558 75.521 175.007 74.192C174.457 72.863 174.173 71.4386 174.173 70.0001Z"
        fill="#001F5C"
      />
      <path
        d="M190.426 74.868C190.426 74.4404 190.772 74.0938 191.2 74.0938H192.517C192.945 74.0938 193.291 74.4404 193.291 74.868V76.185C193.291 76.6126 192.945 76.9593 192.517 76.9593H191.2C190.772 76.9593 190.426 76.6126 190.426 76.185V74.868Z"
        fill="white"
      />
      <path
        d="M182.603 76.9592C182.176 76.9592 181.829 77.3059 181.829 77.7335V79.0505C181.829 79.4781 182.176 79.8248 182.603 79.8248H183.92C184.348 79.8248 184.695 79.4781 184.695 79.0505V77.7335C184.695 77.3059 184.348 76.9592 183.92 76.9592H182.603Z"
        fill="white"
      />
      <path
        d="M202.662 76.9592C202.235 76.9592 201.888 77.3059 201.888 77.7335V79.0505C201.888 79.4781 202.235 79.8248 202.662 79.8248H203.979C204.407 79.8248 204.753 79.4781 204.753 79.0505V77.7335C204.753 77.3059 204.407 76.9592 203.979 76.9592H202.662Z"
        fill="white"
      />
      <path
        d="M187.56 77.7335C187.56 77.3059 187.907 76.9592 188.335 76.9592H189.652C190.079 76.9592 190.426 77.3059 190.426 77.7335V79.0505C190.426 79.4781 190.079 79.8248 189.652 79.8248H188.335C187.907 79.8248 187.56 79.4781 187.56 79.0505V77.7335Z"
        fill="white"
      />
      <path
        d="M208.393 76.9592C207.966 76.9592 207.619 77.3059 207.619 77.7335V79.0505C207.619 79.4781 207.966 79.8248 208.393 79.8248H209.71C210.138 79.8248 210.485 79.4781 210.485 79.0505V77.7335C210.485 77.3059 210.138 76.9592 209.71 76.9592H208.393Z"
        fill="white"
      />
      <path
        d="M211.259 74.0938C210.831 74.0938 210.485 74.4404 210.485 74.868V76.185C210.485 76.6126 210.831 76.9593 211.259 76.9593H212.576C213.004 76.9593 213.35 76.6126 213.35 76.185V74.868C213.35 74.4404 213.004 74.0938 212.576 74.0938H211.259Z"
        fill="white"
      />
      <path
        d="M184.695 74.868C184.695 74.4404 185.041 74.0938 185.469 74.0938H186.786C187.214 74.0938 187.56 74.4404 187.56 74.868V76.185C187.56 76.6126 187.214 76.9593 186.786 76.9593H185.469C185.041 76.9593 184.695 76.6126 184.695 76.185V74.868Z"
        fill="white"
      />
      <path
        d="M205.528 74.0938C205.1 74.0938 204.754 74.4404 204.754 74.868V76.185C204.754 76.6126 205.1 76.9593 205.528 76.9593H206.845C207.272 76.9593 207.619 76.6126 207.619 76.185V74.868C207.619 74.4404 207.272 74.0938 206.845 74.0938H205.528Z"
        fill="white"
      />
    </svg>
  );
};
