import React from 'react';
import {type IconType} from '../..';

export const IconRadioCancel: IconType = ({
  height = 16,
  width = 16,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_430_5)">
        <path
          d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87905 15.1538 3.84569 13.6541 2.34595C12.1543 0.846211 10.121 0.00254057 8 0V0ZM11.648 9.952C11.7659 10.0619 11.8605 10.1943 11.9261 10.3415C11.9916 10.4887 12.0269 10.6476 12.0297 10.8088C12.0326 10.9699 12.003 11.1299 11.9426 11.2794C11.8822 11.4288 11.7924 11.5645 11.6785 11.6785C11.5645 11.7924 11.4288 11.8822 11.2794 11.9426C11.1299 12.0029 10.9699 12.0326 10.8088 12.0297C10.6476 12.0269 10.4887 11.9916 10.3415 11.926C10.1943 11.8605 10.0619 11.7659 9.952 11.648L8 9.6976L6.048 11.648C5.82052 11.86 5.51965 11.9754 5.20877 11.9699C4.89789 11.9644 4.60127 11.8385 4.38141 11.6186C4.16155 11.3987 4.03561 11.1021 4.03012 10.7912C4.02464 10.4804 4.14004 10.1795 4.352 9.952L6.3024 8L4.352 6.048C4.14004 5.82052 4.02464 5.51965 4.03012 5.20876C4.03561 4.89788 4.16155 4.60127 4.38141 4.3814C4.60127 4.16154 4.89789 4.0356 5.20877 4.03012C5.51965 4.02463 5.82052 4.14003 6.048 4.352L8 6.3024L9.952 4.352C10.1795 4.14003 10.4804 4.02463 10.7912 4.03012C11.1021 4.0356 11.3987 4.16154 11.6186 4.3814C11.8385 4.60127 11.9644 4.89788 11.9699 5.20876C11.9754 5.51965 11.86 5.82052 11.648 6.048L9.6976 8L11.648 9.952Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
