import React from 'react';
import {type IconType} from '../../../icons';

export const Afro: IconType = ({height = 160, width = 160, ...props}) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 400 225"
      {...props}
    >
      <path
        d="M199.04 48.6457C199.04 48.6457 190.374 48.4851 184.599 54.0679C178.823 59.6507 178.963 76 178.963 76C176.473 73.7967 174.46 70.9637 173.079 67.7213C171.698 64.4788 170.987 60.9143 171 57.3047C171 44.8444 180.158 34 199.04 34C216.796 34 228 48.563 228 61.3543C228 69.6044 225.534 71.4685 225.534 71.4685C225.534 71.4685 222.788 70.8212 219.826 74.7053C218.631 61.4516 216.232 48.6457 199.04 48.6457Z"
        fill="#001F5C"
      />
    </svg>
  );
};
